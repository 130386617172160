import styled from 'styled-components'
import bannerMobile from '../assets/images/banner_mobile.jpeg'

export const Container = styled.div`
  width: 100%;
  margin: auto;
  //padding-top: 24px;
  padding: 24px;
  .ant-select-arrow {
    color: #c02633;
  }
  .title {
    text-align: center;
  }
  @media screen and (min-width: 480px) {
    width: 60%;
    margin: auto;
    .title {
      text-align: left;
    }
  }
`
export const Image = styled.image`
  padding: 0px;
  background-image: url(${bannerMobile});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;"
`
export const Label = styled.div`
  text-align: left;
  font: normal normal 600 14px/45px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 0.5;
  padding-bottom: 10px;
`
