import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import banner from 'assets/images/banner.jpg'
import bannerMobile from 'assets/images/banner_mobile.jpeg'
import success from 'assets/images/succes.svg'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCourse,
  selectProgram,
  showExamPassed,
  updateExperience,
  updateUniversities,
  updateValue,
} from 'redux/slices/createPHDFormSlice'
import styled from 'styled-components'
import CustomUpload from '../../components/CustomUpload'
import CustomUploadNew from '../../components/CustomUploadNew'
import serverErrorDialog from '../../components/serverErrorDialog'
import showDialog from '../../components/showDialog'
import { yearOptions } from '../../constants/UIConstants'
import axios from '../../utils/axios'
import PaymentService from './PaymentService'
import { Container } from './styles'
// import queryString from 'query-string'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const StyledTableContainer = styled.div`
  background: white;
  border-radius: 16px;

  table thead tr {
    background-color: #efefef;
  }

  table,
  td,
  th {
    border: 1px solid #bebebe;
    padding: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  },

`

const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 576px) {
    width: 500px;
    overflow-x: scroll;
  }
`

const Blink = styled.div`
  @keyframes blink {
    0% {
      color: red;
    }
    100% {
      color: black;
    }
  }
  @-webkit-keyframes blink {
    0% {
      color: red;
    }
    100% {
      color: black;
    }
  }
  .blink {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
    font-size: 24px;
  }
`

const { Option } = Select

const exams = [
  'GATE',
  'UGC',
  'CSIR NET',
  'GPAT',
  'SLET',
  'APRSET',
  'TSSET',
  'ANY OTHER EQUIVALENT EXAM',
]

export default function PHDForm() {
  const [school, setSchool] = useState(null)
  const [examProgress, setExamProgress] = useState(false)
  const [search, setSearch] = useSearchParams()
  const utmSource = search.get('utm_source')
  const utmMedium = search.get('utm_medium')
  const utmCampaign = search.get('utm_campaign')

  const [form] = useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state: any) => state.createPHDForm)
  const ref: any = React.createRef()
  // let query = queryString.parse(navigate.location.search)
  let url = new URLSearchParams(window.location.search)

  const level = url.get('level')
  const course = url.get('course')
  const program = url.get('program')

  useEffect(() => {
    dispatch(selectCourse(url.get('course')))
  }, [])

  useEffect(() => {
    dispatch(selectProgram(url.get('program')))
  }, [])

  function handleFinish() {
    if (!(state.selected.mobile.length == 10)) {
      Modal.error({ content: 'Mobile number must be 10 digits' })
      return
    }

    if (state.selected.caste != 'OC' && !state.selected.casteCertificate) {
      Modal.error({ content: 'Select Upload Cast category certificate' })
      return
    }

    // if (
    //   state.selected.admissionCategory == "Group-A" &&
    //   state.selected.fellowshipCertificate == null
    // ) {
    //   Modal.error({ content: "Please upload fellowship certificate" });
    //   return;
    // }
    // if (
    //   state.selected.admissionCategory == "Group-B" &&
    //   state.selected.admissionSubcategory == null
    // ) {
    //   Modal.error({ content: "Select position for Group - B" });
    //   return;
    // }

    if (state.selected.isEmployer == 'yes') {
      if (
        state.selected.certificate == null ||
        state.selected.payslip == null
      ) {
        Modal.error({
          content:
            'Please fill all employer experience certificate & Employee pay slips',
        })
        return
      }
    }

    const filledRows = state.selected.institutionsAttended.filter(
      (institution) =>
        institution.university ||
        institution.degree ||
        institution.year ||
        institution.cgpa ||
        institution.class ||
        institution.subjects ||
        institution.certificate,
    ).length

    if (filledRows < 4) {
      Modal.error({
        content:
          'Please fill the institution Details. SSC , Inter/Diploma , U.G , P.G',
      })
      return
    }

    if (state.selected.acceptedTerms == false) {
      Modal.error({ content: 'Please accept Declaration' })
      return
    }
    const institutionsAttended = state.selected.institutionsAttended

    for (let i = 0; i < institutionsAttended.length; i++) {
      const institution = institutionsAttended[i]

      // Check if university is filled but certificate is null
      if (institution.university && !institution.certificate) {
        Modal.error({
          content: `Please upload the certificate for ${institution.university}`,
        })
        return
      }
    }

    if (
      state.selected.showExamPassed == 'yes' &&
      state.selected.examPassed == null
    ) {
      Modal.error({
        content: 'Please Enter Professional Qualifying Examination Passed',
      })
      return
    }

    const handleConfirm = () => {
      const data = state?.selected

      Modal.confirm({
        title: 'Preview Your Data',
        width: '80%',
        content: (
          <div>
            <p>
              <strong>Mode of Admission:</strong> {data?.modeOfAdmission}
            </p>
            <p>
              <strong>Name:</strong> {data?.name}
            </p>
            <p>
              <strong>Gender:</strong> {data.gender}
            </p>
            <p>
              <strong>Mobile:</strong> {data.mobile}
            </p>
            <p>
              <strong>Email:</strong> {data.email}
            </p>
            <p>
              <strong>Accept Regulations:</strong>{' '}
              {data?.acceptRegulations ? 'Yes' : 'No'}
            </p>
            <p>
              <strong>Father's Name:</strong> {data?.fatherName}
            </p>
            <p>
              <strong>Mother's Name:</strong> {data.motherName}
            </p>
            <p>
              <strong>Date of Birth:</strong> {data?.dob?.format('YYYY-MM-DD')}
            </p>
            <p>
              <strong>Marital Status:</strong> {data.maritalStatus}
            </p>
            <p>
              <strong>Nationality:</strong> {data.nationality}
            </p>
            <p>
              <strong>Caste:</strong> {data.caste}
            </p>
            <p>
              <strong>Religion:</strong> {data.religion}
            </p>
            <p>
              <strong>Mother Tongue:</strong> {data.motherTongue}
            </p>
            <p>
              <strong>School:</strong> {data.school || 'N/A'}
            </p>
            <p>
              <strong>Specialization:</strong> {data.specialization || 'N/A'}
            </p>
            <p>
              <strong>Is Employer:</strong> {data.isEmployer}
            </p>
            <p>
              <strong>Show Exam Passed:</strong> {data.showExamPassed}
            </p>
            <p>
              <strong>Exam Passed:</strong> {data.examPassed || 'N/A'}
            </p>
            <p>
              <strong>Exam Subject:</strong> {data.examSubject || 'N/A'}
            </p>
            <p>
              <strong>Exam Score:</strong> {data.examScore || 'N/A'}
            </p>
            <p>
              <strong>Exam Valid Upto:</strong>{' '}
              {data?.examValidUpto?.format('YYYY-MM-DD') || 'N/A'}
            </p>
            <p>
              <strong>Admission Category:</strong>{' '}
              {data.admissionCategory || 'N/A'}
            </p>
            <p>
              <strong>Admission Subcategory:</strong>{' '}
              {data.admissionSubcategory || 'N/A'}
            </p>
            <p>
              <strong>Exam Certificate:</strong>
              {data.examCertificate ? (
                <img
                  src={`https://media.campx.in/${data.examCertificate}`}
                  alt={`examCertificate`}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong>Photo:</strong>
              {data.photo ? (
                <img
                  src={`https://media.campx.in/${data.photo}`}
                  alt="Photo"
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong>Address:</strong> {data.address}
            </p>
            <p>
              <strong>District:</strong> {data.district}
            </p>
            <p>
              <strong>State:</strong> {data.state}
            </p>
            <p>
              <strong>Pincode:</strong> {data.pincode}
            </p>

            <p>
              <strong>Institutions Attended:</strong>
            </p>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                // border:
                //   data.institutionsAttended.length > 0
                //     ? '1px solid black'
                //     : 'none',
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: '1px solid black' }}>University</th>
                  <th style={{ border: '1px solid black' }}>Degree</th>
                  <th style={{ border: '1px solid black' }}>Year</th>
                  <th style={{ border: '1px solid black' }}>CGPA</th>
                  <th style={{ border: '1px solid black' }}>Class</th>
                  <th style={{ border: '1px solid black' }}>Subjects</th>
                  <th style={{ border: '1px solid black' }}>Certificate</th>
                </tr>
              </thead>
              <tbody>
                {data.institutionsAttended.length > 0 ? (
                  data.institutionsAttended.map((inst, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', height: '30px' }}>
                        {inst.university || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.degree || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.year || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.cgpa || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.class || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.subjects || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {inst.certificate ? (
                          <img
                            src={`https://media.campx.in/${inst.certificate}`}
                            alt={`Certificate ${index}`}
                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No institutions attended data available</td>
                  </tr>
                )}
              </tbody>
            </table>

            <p>
              <strong>Experience:</strong>
            </p>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: '1px solid black' }}>
                    Organization Name
                  </th>
                  <th style={{ border: '1px solid black' }}>Position</th>
                  <th style={{ border: '1px solid black' }}>From</th>
                  <th style={{ border: '1px solid black' }}>To</th>
                </tr>
              </thead>
              <tbody>
                {data?.experience?.length > 0 ? (
                  data?.experience?.map((exp, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', height: '30px' }}>
                        {exp?.organisation || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {exp?.position || ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {exp?.from ? moment(exp.from).format('YYYY-MM-DD') : ''}
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {exp?.to ? moment(exp.to).format('YYYY-MM-DD') : ''}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      No experience data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <p>
              <strong>Caste Certificate:</strong>{' '}
              {data.casteCertificate ? (
                <img
                  src={`https://media.campx.in/${data.casteCertificate}`}
                  alt={`Cast Certificate `}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong>Fellowship Certificate:</strong>{' '}
              {data.fellowshipCertificate ? (
                <img
                  src={`https://media.campx.in/${data.fellowshipCertificate}`}
                  alt={`fellowshipCertificate`}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong> PaySlip :</strong>{' '}
              {data.payslip ? (
                <img
                  src={`https://media.campx.in/${data.payslip}`}
                  alt={`Cast Certificate `}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong> Experience Certificate :</strong>{' '}
              {data.certificate ? (
                <img
                  src={`https://media.campx.in/${data.certificate}`}
                  alt={`Cast Certificate `}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              ) : (
                ''
              )}
            </p>
            <p>
              <strong>Accepted Terms:</strong>{' '}
              {data.acceptedTerms ? 'Yes' : 'No'}
            </p>

            <p>
              <strong>Is Employer Permission Obtained:</strong>{' '}
              {data.isEmployerPermissionObtained ? 'Yes' : 'No'}
            </p>
          </div>
        ),
        onOk() {
          processPayment() // Proceed to payment if confirmed
        },
        onCancel() {
          console.log('User canceled the payment')
        },
      })
    }

    // Call handleConfirm to show the preview modal before proceeding
    handleConfirm()
    const processPayment = async () => {
      const data = {
        ...state.selected,
        admissionCategory: 'Group-B',
        admissionSubcategory: 'Full-time',
        school: state.selectedCourse,
        specialization: state.selectedProgram,
        utmSource: utmSource,
        utmMedium: utmMedium,
        utmCampaign: utmCampaign,
        caste:
          state.selected.caste === 'OTHER'
            ? state.selected.otherBackwardClass
            : state.selected.caste,
        dob: state?.selected.dob?.format('YYYY-MM-DD'),
        examValidUpto: state?.selected.examValidUpto?.format('YYYY-MM-DD'),
        institutionsAttended: state.selected.institutionsAttended.filter(
          (item) => item.university !== '',
        ),
      }

      setExamProgress(true)
      const amount = 2500

      try {
        const res = await axios.post('/square/phd/validate', data)
        const organisedData = {
          ...data,
          transactionNo: null,
          transactionAmount: amount,
          transactionDate: moment().utc(),
        }

        const verifyPayment = (paymentResponse, data) => {
          const organisedData = {
            ...data.data,
            transactionNo: paymentResponse.razorpay_order_id,
            transactionAmount: amount,
            transactionDate: moment().utc(),
          }
          return axios.post(`/square/phd`, organisedData, {
            params: {
              paymentId: paymentResponse.razorpay_payment_id,
              razorpayOrderId: paymentResponse.razorpay_order_id,
              razorpaySignature: paymentResponse.razorpay_signature,
            },
          })
        }

        const amountWithGst = amount
        const orderRes = await PaymentService.createOrder({
          amount: amountWithGst,
          notes: {
            Name: state.selected.name,
            Email: state.selected.email,
            Mobile: state.selected.mobile,
            'Fee Amount': amountWithGst,
          },
        })
        await PaymentService.makePhdFeePayment(
          orderRes.data,
          {
            amount: amountWithGst,
            name: state.selected.name,
            email: state.selected.email,
            mobile: state.selected.mobile,
            data: data,
          },
          verifyPayment,
          (res) => {
            showDialog({
              title: '',
              footer: false,
              okTitle: 'OK',
              cancelTitle: 'Cancel',
              content: ({ setLoading, hideDialog }) => (
                <Row
                  gutter={[24, 12]}
                  style={{ textAlign: 'center', padding: 24 }}
                >
                  <Col xs={24}>
                    <Image preview={false} src={success} />
                  </Col>
                  <Col xs={24}>
                    <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                      Thank you for choosing AU.
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div style={{ fontSize: 12, color: 'gray' }}>
                      {'We will get back to you with further steps ' +
                        'through the Mail ID. For any queries, Contact Administration.'}
                    </div>
                  </Col>
                  <Col xs={24}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        hideDialog()
                        form.resetFields()
                        window.location.replace('https://anurag.edu.in/')
                      }}
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
              ),
            })
          },
        )
      } catch (e) {
        setExamProgress(false)
        serverErrorDialog({ error: e })
      }
    }
  }

  return (
    <div ref={ref}>
      <div className="mobileHidden">
        <div
          style={{
            padding: 16,
            width: '60%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={200}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              {/* <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a> */}
              <Button
                href={'https://anurag.edu.in/'}
                type="primary"
                size="large"
              >
                REQUEST INFO
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <div className="mobileVisible">
        <div
          style={{
            padding: 16,
            width: '100%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={140}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ alignItems: 'center' }}>
            <Button
              href={'https://anurag.edu.in/request-info/'}
              type="primary"
              size="large"
            >
              REQUEST INFO
            </Button>
            {/* <div>
              <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mobileHidden" style={{ position: 'relative' }}>
        <Image preview={false} width={'100%'} src={banner} />
      </div>
      <div className="mobileVisible" style={{ position: 'relative' }}>
        <Image preview={false} height={160} width={'100%'} src={bannerMobile} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '10%',
            color: 'white',
          }}
        >
          <div
            style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
          >
            LEAD THE LEADERS OF TOMORROW
          </div>
        </div>
      </div>
      <Container>
        <Row gutter={[24, 14]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <h3 style={{ textAlign: 'center' }} className="title">
              Application Form for Admission into{' '}
              {/* <span style={{ color: '#C02633' }}> PART TIME/FULL TIME </span> */}
              Ph D Program
            </h3>
          </Col>
          <Col xs={24} style={{ textAlign: 'center', paddingBottom: 8 }}>
            <h3>
              Last date for submission of online application: 21st September
              2024 ( Saturday )
            </h3>
            <h3>
              Date of Written Test(offline)- 28th September 2024( Saturday 02:00
              P.M - 04:00 P.M )
            </h3>
            <h3>Registration Fee: Rs. 2500</h3>
          </Col>
        </Row>
        <div>{/* <Regulations /> */}</div>
        {state.selected.acceptRegulations && (
          <Form
            onFinish={() => {}}
            form={form}
            layout="vertical"
            initialValues={{
              name: null,
              position: null,
            }}
          >
            <Row gutter={[24, 12]}>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    value={state.selected.name}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'name',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Gender"
                  name={'gender'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.gender}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'gender',
                        }),
                      )
                    }}
                  >
                    {['male', 'female'].map((item, index) => (
                      <Option value={item} key={index}>
                        {item.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Father's/Spouse's Name"
                  name={'fatherName'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.fatherName}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'fatherName',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Mother's Name"
                  name={'motherName'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.motherName}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'motherName',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Date of birth"
                  name={'dob'}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    value={state.selected.dob}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'dob',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Marital Status"
                  name={'MaritalStatus'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.maritalStatus}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'maritalStatus',
                        }),
                      )
                    }}
                  >
                    {['married', 'unmarried'].map((item, index) => (
                      <Option value={item} key={index}>
                        {item.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Nationality"
                  name={'nationality'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.nationality}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'nationality',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Mother Tongue"
                  name={'motherTongue'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.motherTongue}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'motherTongue',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Caste(if other than open category (OC), a category certificate must be enclosed)"
                  name={'caste'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.caste}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'caste',
                        }),
                      )
                    }}
                  >
                    {['OC', 'SC', 'ST', 'OBC', 'PWD', 'OTHER'].map(
                      (item, index) => (
                        <Option value={item} key={index}>
                          {item.toUpperCase()}
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {state.selected.caste == 'OTHER' && (
                <Col xs={24}>
                  <Form.Item label="Specify if you belong to any other Backward Class">
                    <Input
                      value={state.selected.otherCaste}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toUpperCase()
                      }}
                      onChange={(e) => {
                        dispatch(
                          updateValue({
                            value: e.target.value.toUpperCase(),
                            key: 'otherCaste',
                          }),
                        )
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {state.selected.caste != 'OC' && (
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Upload your Caste certificate"
                    name={'caste-certificate'}
                    rules={[{ required: true }]}
                  >
                    <CustomUpload
                      accept=".pdf,image/*"
                      label={
                        'Click to upload your Caste certificate (.pdf, jpeg)'
                      }
                      onChange={(e) => {
                        const fileExtension = e.name.split('.').pop()
                        let formData = new FormData()
                        formData.append('file', e)
                        formData.append('type', 'caste_certificate')
                        formData.append(
                          'fileName',
                          `${state.selected.name}_caste_certificate`,
                        )
                        formData.append('fileExtension', fileExtension)
                        setExamProgress(true)
                        axios
                          .post('/square/phd/upload', formData)
                          .then((res) => {
                            setExamProgress(false)
                            dispatch(
                              updateValue({
                                value: res.data.key,
                                key: 'casteCertificate',
                              }),
                            )
                          })
                          .catch((e) => console.log(e))
                      }}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24}>
                <Form.Item
                  label="Religion"
                  name={'religion'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.religion}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'religion',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  label="Enter your mobile number"
                  name={'mobile'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.mobile}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'mobile',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  label="Enter your E-Mail ID"
                  name={'email'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.email}
                    type="email"
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'email',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Professional Qualifying Examination Passed"
                  name={'professional-qualifying'}
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    value={state.selected.showExamPassed}
                    options={[
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        label: 'No',
                        value: 'no',
                      },
                    ]}
                    onChange={(e) => {
                      dispatch(
                        showExamPassed({
                          value: e.target.value,
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>

              {state.selected.showExamPassed == 'yes' && (
                <Col xs={24}>
                  <Form.Item
                    rules={[{ required: true }]}
                    // name="examPassed"
                    label="Professional Qualifying Examination Passed (attested copy of certificate must be attached)"
                  >
                    <Select
                      value={state.selected.examPassed}
                      onChange={(e) => {
                        dispatch(
                          updateValue({
                            value: e,
                            key: 'examPassed',
                          }),
                        )
                      }}
                    >
                      {exams.map((item, index) => (
                        <Option value={item} key={index}>
                          {item.toUpperCase()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {state.selected.showExamPassed == 'yes' &&
                state.selected.examPassed && (
                  <>
                    <Col xs={8}>
                      <Form.Item
                        name={'examSubject'}
                        rules={[{ required: true }]}
                        label="Professional Qualifying Examination Subject"
                      >
                        <Input
                          value={state.selected.examSubject}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                value: e.target.value.toUpperCase(),
                                key: 'examSubject',
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        label="Professional Qualifying Examination Score"
                        name={'examScore'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.examScore}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                value: e.target.value,
                                key: 'examScore',
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <Form.Item
                        name={'examValidUpto'}
                        rules={[{ required: true }]}
                        label="Professional Qualifying Examination Valid upto"
                      >
                        <DatePicker
                          value={state.selected.examValidUpto}
                          style={{ width: '100%' }}
                          format={'DD-MM-YYYY'}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                value: e,
                                key: 'examValidUpto',
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                      <Form.Item
                        name={'examCertificate'}
                        rules={[{ required: true }]}
                        label="Upload Exam  Rank Card / Certificate"
                      >
                        <CustomUpload
                          accept=".pdf,.doc,.docx"
                          label={
                            'Click to upload your exam certificate(.pdf, doc, docx.)'
                          }
                          onChange={(e) => {
                            const fileExtension = e.name.split('.').pop()
                            const name = form.getFieldValue('name')
                            const profession = form.getFieldValue('position')
                            let formData = new FormData()
                            formData.append('file', e)
                            formData.append('type', 'exam_certificate')
                            formData.append(
                              'fileName',
                              `${state.selected.name}_exam_certificate`,
                            )
                            formData.append('fileExtension', fileExtension)
                            setExamProgress(true)
                            axios
                              .post('/square/phd/upload', formData)
                              .then((res) => {
                                setExamProgress(false)
                                dispatch(
                                  updateValue({
                                    value: res.data.key,
                                    key: 'examCertificate',
                                  }),
                                )
                              })
                              .catch((e) => console.log(e))
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  name={'school'}
                  rules={[{ required: state.selectedCourse ? false : true }]}
                  label="Department for which admission is sought"
                >
                  <Select
                    defaultValue={state.selectedCourse}
                    value={state.selectedCourse}
                    onChange={(e) => {
                      setSchool(e)
                      console.log(e)
                      dispatch(selectCourse(e))
                    }}
                  >
                    {state.phd?.map((item, index) => (
                      <Option value={item.course} key={index}>
                        {item.course}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  name={'specialization'}
                  rules={[{ required: state.selectedProgram ? false : true }]}
                  label="Choose a Specialisation"
                >
                  <Select
                    defaultValue={state.selectedProgram}
                    value={state.selectedProgram}
                    onChange={(e) => {
                      dispatch(selectProgram(e))
                    }}
                  >
                    {state.phd
                      .filter((item) => item.course == state.selectedCourse)[0]
                      ?.programs.map((p, index) => (
                        <Option value={p} key={index}>
                          {p}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              {state.selectedProgram == 'Information Technology' && (
                <Col>
                  <Form.Item
                    label={
                      'The scholars admitted into Information Technology (IT) branch can ' +
                      'opt for a Ph.D. in Computer Science and Engineering or Information technology.'
                    }
                  ></Form.Item>
                </Col>
              )}
              <Col xs={24}>
                <Form.Item
                  name={'modeOfAdmission'}
                  rules={[{ required: true }]}
                  label="Mode of Admission (Select Appropriate one only)"
                >
                  <Radio.Group
                    value={state.modeOfAdmission}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value,
                          key: 'modeOfAdmission',
                        }),
                      )
                    }}
                  >
                    <Radio value={'PartTime'}>Part Time</Radio>
                    <Radio value={'FullTime'}>Full Time</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {/* <Col xs={24}>
                <Form.Item
                  name={"admissionCategory"}
                  rules={[{ required: true }]}
                  label="Category of Admission sought"
                >
                  <Radio.Group
                    value={state.selected.admissionCategory}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value,
                          key: "admissionCategory",
                        })
                      );
                    }}
                  >
                    <Radio value={"Group-A"}>
                      Group-A (with fellowship From any other organization
                      /SPONSORSHIP (attested copy of the certificate must be
                      attached))
                    </Radio>
                    <Radio value={"Group-B"}>
                      Group-B (without fellowship)
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {state.selected.admissionCategory == "Group-A" && (
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Form.Item label="Upload your Fellowship certificate">
                    <CustomUpload
                      accept=".pdf,image/*"
                      label={
                        "Click to upload your Fellowship Certificate (.pdf, jpeg)"
                      }
                      onChange={(e) => {
                        const fileExtension = e.name.split(".").pop();
                        let formData = new FormData();
                        formData.append("file", e);
                        formData.append("type", "fellowship_certificate");
                        formData.append(
                          "fileName",
                          `${state.selected.name}_fellowship_certificate`
                        );
                        formData.append("fileExtension", fileExtension);
                        setExamProgress(true);
                        axios
                          .post("/phd/upload", formData)
                          .then((res) => {
                            setExamProgress(false);
                            dispatch(
                              updateValue({
                                value: res.data.key,
                                key: "fellowshipCertificate",
                              })
                            );
                          })
                          .catch((e) => console.log(e));
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {state.selected.admissionCategory == "Group-B" && (
                <Col xs={24}>
                  <Form.Item label="If Group-B (only applicable for applicant without fellowship)">
                    <Radio.Group
                      value={state.selected.admissionSubcategory}
                      onChange={(e) => {
                        dispatch(
                          updateValue({
                            value: e.target.value,
                            key: "admissionSubcategory",
                          })
                        );
                      }}
                    >
                      <Radio value={"Full-time"}>Full-time</Radio>
                      <Radio value={"Part-time"}>Part-time</Radio>
                      <Radio value={"Anurag University Employee<"}>
                        Anurag University Employee
                      </Radio>
                      <Radio value={"Project Staff"}>Project Staff</Radio>
                      <Radio value={"Sponsored (External Registration)"}>
                        Sponsored (External Registration)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )} */}
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Communication Address"
                  // name={'communication_address'}
                  // rules={[{ required: true }]}
                >
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Address"
                        name={'address'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.address}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'address',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="Dist"
                        name={'district'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.district}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'district',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="State"
                        name={'state'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.state}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'state',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="PinCode"
                        name={'pincode'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.pincode}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'pincode',
                                value: e.target.value,
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item label="Permanent Address">
                  <Row>
                    <Col xs={24}>
                      <Form.Item label="Address">
                        <Input
                          value={state.selected.permAddress}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'permAddress',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Dist">
                        <Input
                          value={state.selected.permDistrict}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'permDistrict',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="State">
                        <Input
                          value={state.selected.permState}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'permState',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Pincode">
                        <Input
                          value={state.selected.permPincode}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'permPincode',
                                value: e.target.value,
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col> */}
              <Wrapper>
                <Col xs={24}>
                  <Form.Item
                    label="Details of Universities/Institutions attended (from 10th Standard onwards) (certificates and mark sheets/grade cards/original degree mandatory)"
                    name={'details'}
                    rules={[{ required: true }]}
                  >
                    <StyledTableContainer>
                      <table>
                        <thead>
                          <th>University/College/ Board</th>
                          <th>Degree obtained (with discipline)</th>
                          <th>Year</th>
                          <th>% Marks/ CGPA</th>
                          <th>Class Obtained (if applicable)</th>
                          <th>Subjects taken (GROUP)</th>
                          <th>Certificate</th>
                        </thead>
                        <tbody>
                          {state.selected.institutionsAttended.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .university
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'university',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .degree
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'degree',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Select
                                    style={{ width: 80 }}
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .year
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e,
                                          key: 'year',
                                        }),
                                      )
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {yearOptions.map((item, index) => (
                                      <Option value={item} key={index}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .cgpa
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value,
                                          key: 'cgpa',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .class
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'class',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .subjects
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'subjects',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <CustomUploadNew
                                    accept=".pdf,image/*"
                                    label={'Upload'}
                                    validation={
                                      state.selected.institutionsAttended[index]
                                        .university
                                    }
                                    onChange={(e) => {
                                      const fileExtension = e.name
                                        .split('.')
                                        .pop()
                                      let formData = new FormData()
                                      formData.append('file', e)
                                      formData.append(
                                        'type',
                                        'university_certificate',
                                      )
                                      formData.append(
                                        'fileName',
                                        `${state.selected.name}_${state.selected.institutionsAttended[index].university}_education_certificate`,
                                      )
                                      formData.append(
                                        'fileExtension',
                                        fileExtension,
                                      )
                                      setExamProgress(true)

                                      axios
                                        .post('/square/phd/upload', formData)
                                        .then((res) => {
                                          setExamProgress(false)
                                          dispatch(
                                            updateUniversities({
                                              value: res.data.key,
                                              key: 'certificate',
                                              index: index,
                                            }),
                                          )
                                        })
                                        .catch((e) => console.log(e))
                                    }}
                                  />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </StyledTableContainer>
                  </Form.Item>
                </Col>
              </Wrapper>

              <Col xs={24}>
                <Form.Item
                  label={'Employed'}
                  name={'employed'}
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    value={state.selected.isEmployer}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          key: 'isEmployer',
                          value: e.target.value,
                        }),
                      )
                    }}
                  >
                    <Radio value={'yes'}>YES</Radio>
                    <Radio value={'no'}>NO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {state.selected.isEmployer == 'yes' && (
                <>
                  <Wrapper>
                    <Col xs={24}>
                      <Form.Item
                        label="Professional Experience (Teaching/Research/Industrial), if any (proof to be
attached only at the time of interview only)."
                        name={'professional experience'}
                        rules={[{ required: true }]}
                      >
                        <StyledTableContainer>
                          <table>
                            <thead>
                              <th>Name of the organization</th>
                              <th>Position held</th>
                              <th>From</th>
                              <th>To</th>
                            </thead>
                            <tbody>
                              {state.selected.experience.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <Input
                                      value={
                                        state.selected.experience[index]
                                          .organisation
                                      }
                                      onInput={(e: any) => {
                                        e.target.value =
                                          e.target.value.toUpperCase()
                                      }}
                                      onChange={(e) => {
                                        dispatch(
                                          updateExperience({
                                            index: index,
                                            value: e.target.value.toUpperCase(),
                                            key: 'organisation',
                                          }),
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      value={
                                        state.selected.experience[index]
                                          .position
                                      }
                                      onChange={(e) => {
                                        dispatch(
                                          updateExperience({
                                            index: index,
                                            value: e.target.value.toUpperCase(),
                                            key: 'position',
                                          }),
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <DatePicker
                                      format={'DD-MM-YYYY'}
                                      value={
                                        state.selected.experience[index].from
                                      }
                                      onChange={(e) => {
                                        dispatch(
                                          updateExperience({
                                            index: index,
                                            value: e,
                                            key: 'from',
                                          }),
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <DatePicker
                                      format={'DD-MM-YYYY'}
                                      value={
                                        state.selected.experience[index].to
                                      }
                                      onChange={(e) => {
                                        dispatch(
                                          updateExperience({
                                            index: index,
                                            value: e,
                                            key: 'to',
                                          }),
                                        )
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </StyledTableContainer>
                      </Form.Item>
                    </Col>
                  </Wrapper>
                  {/* <Col xs={12}>
                    <Form.Item label={'Employer Name'}>
                      <Input
                        value={state.selected.employerName}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerName',
                              value: e.target.value.toUpperCase(),
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={'Employer Email'}>
                      <Input
                        value={state.selected.employerEmail}
                        type="email"
                        onInput={(e: any) => {
                          e.target.value = e.target.value.toUpperCase()
                        }}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerEmail',
                              value: e.target.value.toUpperCase(),
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={'Employer Website'}>
                      <Input
                        value={state.selected.employerWebsite}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerWebsite',
                              value: e.target.value,
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col xs={12}>
                    <Form.Item label={'Employer Address'}>
                      <Input
                        value={state.selected.employerAddress}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.toUpperCase()
                        }}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerAddress',
                              value: e.target.value.toUpperCase(),
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col> */}

                  {/* <Col xs={12}>
                    <Form.Item label={'Employer Phone 1'}>
                      <Input
                        value={state.selected.employerPhone1}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerPhone1',
                              value: e.target.value,
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label={'Employer Phone 2'}>
                      <Input
                        value={state.selected.employerPhone2}
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'employerPhone2',
                              value: e.target.value,
                            }),
                          )
                        }}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Form.Item
                      name={'payslip'}
                      rules={[{ required: true }]}
                      label="Upload Payslip"
                    >
                      <CustomUpload
                        accept="image/jpeg"
                        label={
                          'Please upload your salary payslips  (.jpeg,.jpg)'
                        }
                        onChange={(e) => {
                          const fileExtension = e.name.split('.').pop()
                          let formData = new FormData()
                          formData.append('file', e)
                          formData.append('type', 'photo')
                          formData.append(
                            'fileName',
                            `${state.selected.name}_photo`,
                          )
                          formData.append('fileExtension', fileExtension)
                          setExamProgress(true)
                          // const options = {
                          //   onUploadProgress: (event) => {
                          //     const { loaded, total } = event;
                          //     let percent = Math.floor((loaded * 100 / total))
                          //     console.log(loaded, total, percent)
                          //   }
                          // }
                          axios
                            .post('/square/phd/upload', formData)
                            .then((res) => {
                              setExamProgress(false)
                              dispatch(
                                updateValue({
                                  value: res.data.key,
                                  key: 'payslip',
                                }),
                              )
                            })
                            .catch((e) => console.log(e))
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Form.Item
                      name={'certificate'}
                      rules={[{ required: true }]}
                      label="Upload Experience  Certificate "
                    >
                      <CustomUpload
                        accept="image/jpeg"
                        label={
                          'Please upload experience certificate (.jpeg,.jpg)'
                        }
                        onChange={(e) => {
                          const fileExtension = e.name.split('.').pop()
                          let formData = new FormData()
                          formData.append('file', e)
                          formData.append('type', 'photo')
                          formData.append(
                            'fileName',
                            `${state.selected.name}_photo`,
                          )
                          formData.append('fileExtension', fileExtension)
                          setExamProgress(true)
                          // const options = {
                          //   onUploadProgress: (event) => {
                          //     const { loaded, total } = event;
                          //     let percent = Math.floor((loaded * 100 / total))
                          //     console.log(loaded, total, percent)
                          //   }
                          // }
                          axios
                            .post('/square/phd/upload', formData)
                            .then((res) => {
                              setExamProgress(false)
                              dispatch(
                                updateValue({
                                  value: res.data.key,
                                  key: 'certificate',
                                }),
                              )
                            })
                            .catch((e) => console.log(e))
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label={
                        'Whether employer’s permission obtained ?' +
                        ' (At the time of Interview “No objection certificate (NOC)” from the current employer\n' +
                        'shall be submitted)'
                      }
                    >
                      <Radio.Group
                        onChange={(e) => {
                          dispatch(
                            updateValue({
                              key: 'isEmployerPermissionObtained',
                              value: e.target.value == 'yes' ? true : false,
                            }),
                          )
                        }}
                      >
                        <Radio value={'yes'}>YES</Radio>
                        <Radio value={'no'}>NO</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Form.Item
                  name={'photo'}
                  rules={[{ required: true }]}
                  label="Upload Photo"
                >
                  <CustomUpload
                    accept="image/jpeg"
                    label={'Please upload passport size photo(.jpeg,.jpg)'}
                    onChange={(e) => {
                      const fileExtension = e.name.split('.').pop()
                      let formData = new FormData()
                      formData.append('file', e)
                      formData.append('type', 'photo')
                      formData.append(
                        'fileName',
                        `${state.selected.name}_photo`,
                      )
                      formData.append('fileExtension', fileExtension)
                      setExamProgress(true)
                      // const options = {
                      //   onUploadProgress: (event) => {
                      //     const { loaded, total } = event;
                      //     let percent = Math.floor((loaded * 100 / total))
                      //     console.log(loaded, total, percent)
                      //   }
                      // }
                      axios
                        .post('/square/phd/upload', formData)
                        .then((res) => {
                          setExamProgress(false)
                          dispatch(
                            updateValue({
                              value: res.data.key,
                              key: 'photo',
                            }),
                          )
                        })
                        .catch((e) => console.log(e))
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  name={'acceptedTerms'}
                  rules={[{ required: true, message: '' }]}
                  label={'Declaration'}
                >
                  <Checkbox
                    // value={state.selected.acceptedTerms}
                    checked={state.selected.acceptedTerms}
                    onChange={(e) =>
                      dispatch(
                        updateValue({
                          key: 'acceptedTerms',
                          value: e.target.checked,
                        }),
                      )
                    }
                  >
                    I hereby declare that the entries made in this application
                    form are correct to the best of my knowledge and belief. If
                    selected for admission, I promise to abide by the rules and
                    regulations of the Anurag University (AU) as amended from
                    time to time. I note that the decision of the Anurag
                    University is final in regard to selection for admission and
                    assignment to a particular department and field of study.
                    The Anurag University shall have the right to take any
                    action it deems fit, including expulsion, against me at any
                    time after my admission, if it is found that information
                    furnished by me are false or incorrect. I shall abide by the
                    decision of the Institute, which shall be final.
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} style={{ textAlign: 'center' }}>
                <Form.Item>
                  <Button
                    disabled={examProgress}
                    onClick={handleFinish}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    Pay and Submit
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} style={{ textAlign: 'center' }}>
                <Form.Item>
                  <div>
                    (If you have any issues in from submission please contact us
                    at <a type="link">auphdadmissions@anurag.edu.in</a>)
                  </div>
                  <div>
                    Contact numbers - <a type="link">8712158602</a>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </div>
  )
}
