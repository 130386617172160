import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  loading: true,
  selectedLevel: null,
  selectedCourse: null,
  selectedProgram: null,
  levelOfStudy: [
    { id: 1, name: 'Undergraduate (UG)', value: 'UG' },
    { id: 2, name: 'Postgraduate (PG)', value: 'PG' },
    { id: 3, name: 'Doctorate Programs (Ph.D)', value: 'Ph.D' },
  ],
  courses: [
    {
      id: 1,
      name: 'School of Engineering',
      levelId: 1,
      image: 'engineering.svg',
    },
    {
      id: 2,
      name: 'School of Management',
      levelId: 1,
      image: 'management.svg',
    },
    {
      id: 3,
      name: 'School of Pharmacy',
      levelId: 1,
      image: 'pharmacy.svg',
    },
    {
      id: 4,
      name: 'School of Liberal Arts',
      levelId: 1,
      image: 'arts.svg',
    },
    {
      id: 5,
      name: 'School of Humanity and Sciences',
      levelId: 1,
      image: 'science.svg',
    },
    {
      id: 6,
      name: 'School of Agricultural Sciences',
      levelId: 1,
      image: 'agricultural.svg',
    },
    {
      id: 7,
      name: 'School of Engineering',
      levelId: 2,
      image: 'engineering.svg',
    },
    {
      id: 8,
      name: 'School of Management',
      levelId: 2,
      image: 'management.svg',
    },
    {
      id: 9,
      name: 'School of Pharmacy',
      levelId: 2,
      image: 'pharmacy.svg',
    },
    {
      id: 10,
      name: 'School of Engineering',
      levelId: 3,
      image: 'engineering.svg',
    },
    {
      id: 11,
      name: 'School of Management',
      levelId: 3,
      image: 'management.svg',
    },
    {
      id: 12,
      name: 'School of Pharmacy',
      levelId: 3,
      image: 'pharmacy.svg',
    },
    {
      id: 13,
      name: 'School of Science and Humanities',
      levelId: 3,
      image: 'science.svg',
    },
  ],
  programs: [
    {
      id: 1,
      name: 'Chemical Engineering',
      courseId: 1,
      image: 'Chemical-Engineering-2.jpg',
    },
    {
      id: 2,
      name: 'Computer Science and Engineering',
      courseId: 1,
      image: 'Computer-Science-Engineering.jpg',
    },
    {
      id: 3,
      name: 'Electrical and Electronics Engineering',
      courseId: 1,
      image: 'Electrical-Electronics-Engineering.jpg',
    },
    {
      id: 4,
      name: 'Electronics and Communication Engineering',
      courseId: 1,
      image: 'Electronics-Communication-Engineering.jpg',
    },
    {
      id: 5,
      name: 'Information Technology',
      courseId: 1,
      image: 'Information-Technology.jpg',
    },
    {
      id: 6,
      name: 'Mechanical Engineering',
      courseId: 1,
      image: 'Mechanical-Engineering.jpg',
    },
    {
      id: 7,
      name: 'Construction Technology and Management',
      courseId: 1,
      image: 'construction-1.jpg',
    },
    {
      id: 8,
      name: 'Computer Science and Systems Engineering',
      courseId: 1,
      image: 'Computer-Science-Engineering.jpg',
    },
    {
      id: 9,
      name: 'Artificial Intelligence and Machine Learning',
      courseId: 1,
      image: 'Artificial-Intelligence-2.jpg',
    },
    {
      id: 10,
      name: 'Artificial Intelligence',
      courseId: 1,
      image: 'Webp.net-resizeimage-5.png',
    },
    {
      id: 11,
      name: 'Computer Science Engineering (Data Science)',
      courseId: 1,
      image: 'computer-science-1.jpg',
    },
    {
      id: 12,
      name: 'Computer Science Engineering (Cyber Security)',
      courseId: 1,
      image: 'Webp.net-resizeimage-3.png',
    },
    {
      id: 13,
      name: 'Civil Engineering',
      courseId: 1,
      image: 'Civil-Engineering (1).jpg',
    },

    {
      id: 14,
      name: 'VLSI System Design',
      courseId: 7,
      image: 'VLSI-System-Design.jpg',
    },
    {
      id: 15,
      name: 'Structural Engineering',
      courseId: 7,
      image: 'Structural-Engineering.jpg',
    },
    {
      id: 16,
      name: 'Embedded Systems',
      courseId: 7,
      image: 'Webp.net-resizeimage-6.png',
    },
    { id: 17, name: 'Cyber Security', courseId: 7, image: 'cyber-sec.png' },
    {
      id: 10,
      name: 'Artificial Intelligence',
      courseId: 7,
      image: 'Artificial-Intelligence-2.jpg',
    },
    {
      id: 18,
      name: 'Data Sciences',
      courseId: 7,
      image: 'data-sciences-1.png',
    },
    {
      id: 18,
      name: 'Construction Technology and Management',
      courseId: 7,
      image: 'construction-1.jpg',
    },
    {
      id: 20,
      name: 'Additive Manufacturing',
      courseId: 7,
      image: 'Webp.net-resizeimage-7.png',
    },
    {
      id: 19,
      name: 'Robotics and Automation',
      courseId: 7,
      image: 'Webp.net-resizeimage-10.png',
    },
    {
      id: 21,
      name: 'Machine Design',
      courseId: 7,
      image: 'Machine-Design.jpg',
    },
    {
      id: 22,
      name: 'Power Electronics and Electrical Drives',
      courseId: 7,
      image: 'Power-Electronics-Electrical-Drives.jpg',
    },
    {
      id: 23,
      name: 'Computer Science and Engineering',
      courseId: 7,
      image: 'Computer-Science-Engineering.jpg',
    },

    {
      id: 24,
      name: 'Chemical Engineering',
      courseId: 10,
      image: 'Chemical-Engineering-2.jpg',
    },
    {
      id: 25,
      name: 'Computer Science and Engineering',
      courseId: 10,
      image: 'Computer-Science-Engineering.jpg',
    },
    {
      id: 26,
      name: 'Electrical and Electronics Engineering',
      courseId: 10,
      image: 'Electrical-Electronics-Engineering.jpg',
    },
    {
      id: 27,
      name: 'Electronics and Communication Engineering',
      courseId: 10,
      image: 'Electronics-Communication-Engineering.jpg',
    },
    {
      id: 28,
      name: 'Information Technology',
      courseId: 10,
      image: 'Information-Technology.jpg',
      text:
        '(The scholars admitted ' +
        'into Information Technology (IT) branch can opt for a Ph.D. in Computer Science and Engineering or ' +
        'Information technology.)',
    },
    {
      id: 29,
      name: 'Mechanical Engineering',
      courseId: 10,
      image: 'Mechanical-Engineering.jpg',
    },
    {
      id: 30,
      name: 'CSE (specialization in Artificial Intelligence)',
      courseId: 10,
      image: 'Artificial-Intelligence-2.jpg',
    },
    {
      id: 31,
      name: 'Civil Engineering',
      courseId: 10,
      image: 'Civil-Engineering (1).jpg',
    },

    {
      id: 32,
      name: 'Bachelor of Business Administration',
      courseId: 2,
      image: 'Bachelor-of-Business-Administration.png',
    },
    {
      id: 33,
      name: 'Bachelor of Science Agriculture',
      courseId: 2,
      image: 'Bachelor-of-Science-Hons-Agriculture.png',
    },
    {
      id: 34,
      name: 'Master of Business Administration',
      courseId: 8,
      image: 'MBA.png',
    },
    { id: 35, name: 'Management', courseId: 11, image: 'MBA..png' },

    { id: 36, name: 'Bachelor of Pharmacy', courseId: 3 },
    {
      id: 37,
      name: 'Doctor of Pharmacy',
      courseId: 3,
      image: 'Doctor-of-Pharmacy-2.jpg',
    },
    {
      id: 38,
      name: 'Doctor of Pharmacy',
      courseId: 9,
      image: 'Doctor-of-Pharmacy-2.jpg',
    },
    { id: 39, name: 'Pharmaceutics', courseId: 9, image: 'Pharmaceutics.jpg' },
    { id: 40, name: 'Pharmacology', courseId: 9, image: 'Pharmacology,jpg' },
    {
      id: 41,
      name: 'Pharmaceutical Analysis',
      courseId: 9,
      image: 'Pharma-Analysis.jpg',
    },
    {
      id: 42,
      name: 'Industrial Pharmacy',
      courseId: 9,
      image: 'Industrial-Pharmacy.jpg',
    },
    {
      id: 43,
      name: 'Pharma.D. (PB)',
      courseId: 9,
      image: 'Doctor-of-Pharmacy-2.jpg',
    },
    { id: 44, name: 'Pharmacy', courseId: 9, image: 'Doctor-of-Pharmacy.jpg' },
    {
      id: 45,
      name: 'Pharmaceutical Regulatory Affairs',
      courseId: 9,
      image: 'pharmacy.svg',
    },
    { id: 46, name: 'Pharmacy', courseId: 12, image: 'pharmacy.svg' },

    {
      id: 47,
      name: 'Bachelor of Commerce (Hons)',
      courseId: 4,
      image: 'Bachelor-of-Commerce-Hons.png',
    },
    {
      id: 48,
      name: 'Bachelor of Journalism',
      courseId: 4,
      image: 'Bachelor-of-Arts-Economics.png',
    },
    {
      id: 49,
      name: 'Bachelor of Arts - Economics',
      courseId: 4,
      image: 'Bachelor-of-Journalism.png',
    },
    // {id: 50, name: "Spanish", courseId: 4,image: null},
    // {id: 51, name: "German", courseId: 4,image: null},
    // {id: 52, name: "French", courseId: 4,image: null},

    { id: 53, name: 'Maths', courseId: 5, image: null },
    { id: 54, name: 'Physics', courseId: 5, image: null },
    { id: 55, name: 'Chemistry', courseId: 5, image: null },
    { id: 56, name: 'English', courseId: 5, image: null },
    { id: 57, name: 'Psychology', courseId: 5, image: null },
    { id: 58, name: 'Statistics', courseId: 5, image: null },
    { id: 59, name: 'Maths', courseId: 13, image: null },
    { id: 60, name: 'Physics', courseId: 13, image: null },
    { id: 61, name: 'Chemistry', courseId: 13, image: null },
    { id: 62, name: 'English', courseId: 13, image: null },

    {
      id: 63,
      name: 'Bachelor of Science (Hons) Agriculture',
      courseId: 6,
      image: 'Bachelor-of-Commerce-Hons.png',
    },
    // {id: 64, name: "Agronomy", courseId: 6},
    // {id: 65, name: "Generic & Plant Breeding", courseId: 6},
    // {id: 66, name: "Plant Pathology", courseId: 6},
    // {id: 67, name: "Soil Science & Agricultural Chemistry", courseId: 6},
    // {id: 68, name: "Crop Physiology", courseId: 6},
    // {id: 69, name: "Horticulture", courseId: 6},
    // {id: 70, name: "Agriculture Extension", courseId: 6},
    // {id: 71, name: "Agricultural Microbiology", courseId: 6},
  ],
}

const slice = createSlice({
  name: 'program',
  initialState: initialState,
  reducers: {
    selectCourse(state, action) {
      state.selectedCourse = action.payload
    },
    selectProgram(state, action) {
      state.selectedProgram = action.payload
    },
    selectLevel(state, action) {
      state.selectedLevel = action.payload
    },
  },
})

export const { selectCourse, selectLevel, selectProgram } = slice.actions

export default slice.reducer
