import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 24px;
  .ant-select-arrow {
    color: #c02633;
  }
  .title {
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    margin: 25px 0;
    .title {
      text-align: left;
    }
  }
`
