export const PreviewData = ({ data }) => {
  return (
    <div>
      <p>
        <strong>Mode of Admission:</strong> {data?.modeOfAdmission}
      </p>
      <p>
        <strong>Name:</strong> {data?.name}
      </p>
      <p>
        <strong>Gender:</strong> {data.gender}
      </p>
      <p>
        <strong>Mobile:</strong> {data.mobile}
      </p>
      <p>
        <strong>Email:</strong> {data.email}
      </p>
      <p>
        <strong>Accept Regulations:</strong>{' '}
        {data?.acceptRegulations ? 'Yes' : 'No'}
      </p>
      <p>
        <strong>Father's Name:</strong> {data?.fatherName}
      </p>
      <p>
        <strong>Mother's Name:</strong> {data.motherName}
      </p>
      <p>
        <strong>Date of Birth:</strong> {data?.dob?.format('YYYY-MM-DD')}
      </p>
      <p>
        <strong>Marital Status:</strong> {data.maritalStatus}
      </p>
      <p>
        <strong>Nationality:</strong> {data.nationality}
      </p>
      <p>
        <strong>Caste:</strong> {data.caste}
      </p>
      <p>
        <strong>Religion:</strong> {data.religion}
      </p>
      <p>
        <strong>Mother Tongue:</strong> {data.motherTongue}
      </p>
      <p>
        <strong>School:</strong> {data.school || 'N/A'}
      </p>
      <p>
        <strong>Specialization:</strong> {data.specialization || 'N/A'}
      </p>
      <p>
        <strong>Admission Category:</strong> {data.admissionCategory || 'N/A'}
      </p>
      <p>
        <strong>Admission Subcategory:</strong>{' '}
        {data.admissionSubcategory || 'N/A'}
      </p>
      <p>
        <strong>Exam Certificate:</strong>
        {data.examCertificate ? (
          <img
            src={`https://media.campx.in/${data.examCertificate}`}
            alt={`examCertificate`}
            style={{ maxWidth: '150px', maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
      </p>
      <p>
        <strong>Photo:</strong>
        {data.photo ? (
          <img
            src={`https://media.campx.in/${data.photo}`}
            alt="Photo"
            style={{ maxWidth: '150px', maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
      </p>
      <p>
        <strong>Address:</strong> {data.address}
      </p>
      <p>
        <strong>District:</strong> {data.district}
      </p>
      <p>
        <strong>State:</strong> {data.state}
      </p>
      <p>
        <strong>Pincode:</strong> {data.pincode}
      </p>

      <p>
        <strong>Institutions Attended:</strong>
      </p>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>University</th>
            <th style={{ border: '1px solid black' }}>Degree</th>
            <th style={{ border: '1px solid black' }}>Year</th>
            <th style={{ border: '1px solid black' }}>CGPA</th>
            <th style={{ border: '1px solid black' }}>Class</th>
            <th style={{ border: '1px solid black' }}>Subjects</th>
            <th style={{ border: '1px solid black' }}>Certificate</th>
          </tr>
        </thead>
        <tbody>
          {data?.institutionsAttended?.length > 0 ? (
            data?.institutionsAttended?.map((inst, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', height: '30px' }}>
                  {inst.university || ''}
                </td>
                <td style={{ border: '1px solid black' }}>
                  {inst.degree || ''}
                </td>
                <td style={{ border: '1px solid black' }}>{inst.year || ''}</td>
                <td style={{ border: '1px solid black' }}>{inst.cgpa || ''}</td>
                <td style={{ border: '1px solid black' }}>
                  {inst.class || ''}
                </td>
                <td style={{ border: '1px solid black' }}>
                  {inst.subjects || ''}
                </td>
                <td style={{ border: '1px solid black' }}>
                  {inst.certificate ? (
                    <img
                      src={`https://media.campx.in/${inst.certificate}`}
                      alt={`Certificate ${index}`}
                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No institutions attended data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <p>
        <strong>Caste Certificate:</strong>{' '}
        {data.casteCertificate ? (
          <img
            src={`https://media.campx.in/${data.casteCertificate}`}
            alt={`Cast Certificate `}
            style={{ maxWidth: '150px', maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
      </p>
      <p>
        <strong>Fellowship Certificate:</strong>{' '}
        {data.fellowshipCertificate ? (
          <img
            src={`https://media.campx.in/${data.fellowshipCertificate}`}
            alt={`fellowshipCertificate`}
            style={{ maxWidth: '150px', maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
      </p>
      <p>
        <strong> Experience Certificate :</strong>{' '}
        {data.certificate ? (
          <img
            src={`https://media.campx.in/${data.certificate}`}
            alt={`Cast Certificate `}
            style={{ maxWidth: '150px', maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
      </p>
      <p>
        <strong>Accepted Terms:</strong> {data.acceptedTerms ? 'Yes' : 'No'}
      </p>
    </div>
  )
}
