import Axios from 'axios'

let axios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'x-tenant-id':
      process.env.NODE_ENV === 'development' ? 'aupulse' : 'aupulse',
    accept: 'application/json',
  },
})

export default axios
