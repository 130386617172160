import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { rootReducer } from './root'

const observableMiddleware = createEpicMiddleware()

let middleware = [observableMiddleware]

if (process.env.NODE_ENV == 'development') {
  middleware.push(logger)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
})

export default store
