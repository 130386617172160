import React, { useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import banner from '../../assets/images/banner.jpg'
import bannerMobile from '../../assets/images/banner_mobile.jpeg'
import { Container } from './styles'
import styled from 'styled-components'
import {
  selectCourse,
  selectLevel,
  selectProgram,
  updateValue,
  setLoading,
} from 'redux/slices/createGeneralApplicationSlice'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../utils/axios'
import moment from 'moment'
import serverErrorDialog from '../../components/serverErrorDialog'
import showDialog from '../../components/showDialog'
import success from '../../assets/images/succes.svg'
import { useNavigate } from 'react-router-dom'

const { Option } = Select
const LabelRequired = styled.div`
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`

function GeneralApplication(props) {
  const state = useSelector((state: any) => state.createGeneralApplication)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // let query = (window.location.search)
  console.log(state, 'state')
  let url = new URLSearchParams(window.location.search)

  useEffect(() => {
    dispatch(selectCourse(url.get('course')))
  }, [])

  useEffect(() => {
    dispatch(selectLevel(url.get('level')))
  }, [])

  useEffect(() => {
    dispatch(selectProgram(url.get('program')))
  }, [])

  function onSubmit() {
    if (!(state.selected.mobile.length == 10)) {
      Modal.error({ content: 'Mobile number must be 10 digits' })
      return
    }
    let data = {
      levelOfStudy: state.selectedLevel,
      specialization: state.selectedProgram,
      school: state.selectedCourse,
      fullName: state.selected.fullName,
      email: state.selected.email,
      mobile: state.selected.mobile,
      city: state.selected.city,
    }
    dispatch(setLoading(true))
    axios
      .post('/square/application-forms', data)
      .then((res) => {
        dispatch(setLoading(false))
        let data = {
          AuthToken: 'ANURAGUNIVERSITY-08-05-2021',
          Source: 'anuraguniversity',
          FirstName: state.selected.fullName,
          MobileNumber: state.selected.mobile,
          email: state.selected.email,
          Address: state.selected.city,
          LeadSource: 'Application Form',
          LeadType: 'Online',
          Field1: url.get('utm_source') || '',
          Field2: url.get('utm_medium') || '',
          LeadName: url.get('utm_campaign') || '',
          Course: state.selectedLevel,
          Center: state.selectedCourse,
          Location: state.selectedProgram,
        }
        fetch('https://thirdpartyapi.extraaedge.com/api/SaveRequest', {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then((res) => {})
          .catch((err) => {
            console.log(err)
          })

        showDialog({
          title: '',
          footer: false,
          okTitle: 'OK',
          cancelTitle: 'Cancel',
          content: ({ setLoading, hideDialog }) => (
            <Row gutter={[24, 12]} style={{ textAlign: 'center', padding: 24 }}>
              <Col xs={24}>
                <Image preview={false} src={success} />
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                  Thank you for choosing AU.
                </div>
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 12, color: 'gray' }}>
                  {'We will get back to you with further steps ' +
                    'through the Mail ID. For any queries, Contact Administration.'}
                </div>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    hideDialog()
                    window.location.replace('https://anurag.edu.in/')
                  }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          ),
        })
      })
      .catch((e) => {
        dispatch(setLoading(false))
        serverErrorDialog({ error: e })
      })
  }

  return (
    <div>
      <div className="mobileHidden">
        <div
          style={{
            padding: 16,
            width: '60%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={200}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a>
              <Button
                href={'https://anurag.edu.in/request-info/'}
                type="primary"
                size="large"
              >
                REQUEST INFO
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <div className="mobileVisible">
        <div
          style={{
            padding: 16,
            width: '100%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={140}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ alignItems: 'center' }}>
            <Button
              href={'https://anurag.edu.in/request-info/'}
              type="primary"
              size="large"
            >
              REQUEST INFO
            </Button>
            <div>
              <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileHidden" style={{ position: 'relative' }}>
        <Image preview={false} width={'100%'} src={banner} />
      </div>
      <div className="mobileVisible" style={{ position: 'relative' }}>
        <Image preview={false} height={160} width={'100%'} src={bannerMobile} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '0%',
            color: 'white',
          }}
        >
          <div
            style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
          >
            LEAD THE LEADERS OF TOMORROW
          </div>
        </div>
      </div>
      <Container>
        <Row gutter={[24, 24]} style={{ textAlign: 'center' }}>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <h1>BEGIN YOUR NEW JOURNEY</h1>
            <div style={{ fontSize: '20px' }}>
              Choose from our extensive selection of exciting programs and
              become part of the future society.
            </div>
          </Col>
        </Row>
        <Form layout="vertical">
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Which Level are you interested in?</label>
                <Select
                  value={state.selectedLevel}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    dispatch(selectLevel(e))
                    dispatch(selectCourse(null))
                    dispatch(selectProgram(null))
                  }}
                >
                  {state.data.map((item, index) => (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Which School are you interested in?</label>
                <Select
                  value={state.selectedCourse}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    dispatch(selectCourse(e))
                    dispatch(selectProgram(null))
                  }}
                >
                  {state.data
                    .filter((item) => item.name == state?.selectedLevel)[0]
                    ?.courses.map((item, index) => (
                      <Option value={item.course} key={index}>
                        {item.course}
                      </Option>
                    ))}
                </Select>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Which Specialisation are you interested in?</label>
                <Select
                  value={state.selectedProgram}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    dispatch(selectProgram(e))
                  }}
                >
                  {state.data
                    .filter((item) => item.name == state?.selectedLevel)[0]
                    ?.courses.filter(
                      (item) => item.course == state?.selectedCourse,
                    )[0]
                    ?.programs?.map((item, index) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Full Name</label>
                <Input
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toUpperCase()
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateValue({
                        key: 'fullName',
                        value: e.target.value,
                      }),
                    )
                  }}
                />
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Email Address</label>
                <Input
                  onChange={(e) => {
                    dispatch(
                      updateValue({
                        key: 'email',
                        value: e.target.value,
                      }),
                    )
                  }}
                />
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>Phone No</label>
                <Input
                  onChange={(e) => {
                    dispatch(
                      updateValue({
                        key: 'mobile',
                        value: e.target.value,
                      }),
                    )
                  }}
                />
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <label>City or Town</label>
                <Input
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toUpperCase()
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateValue({
                        key: 'city',
                        value: e.target.value,
                      }),
                    )
                  }}
                />
              </Space>
            </Col>

            <Col xs={24}>
              <Space
                direction="vertical"
                style={{ width: '100%', textAlign: 'center' }}
              >
                <Button
                  loading={state.loading}
                  onClick={onSubmit}
                  type="primary"
                  size="large"
                >
                  SUBMIT
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

export default GeneralApplication
