import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  message,
  Modal,
  Image,
  Divider,
  Space,
} from 'antd'
// import { Container } from './styles'
import banner from '../../assets/images/banner.jpg'
import bannerMobile from '../../assets/images/banner_mobile.jpeg'
import { useForm } from 'antd/lib/form/Form'
import axios from 'utils/axios'
import showDialog from 'components/showDialog'
import serverErrorDialog from 'components/serverErrorDialog'
import { Container } from 'pages/styles'

import success from '../../assets/images/succes.svg'
import CustomUpload from 'components/CustomUpload'

const { Option } = Select

const data: any = [
  {
    school: 'Engineering',
    programs: [
      'Chemical Engineering',
      'Computer Science & Engineering',
      'Electrical & Electronics Engineering',
      'Electronics & Communication Engineering',
      'Information Technology',
      'Mechanical Engineering',
      'Construction Technology and Management',
      'Computer Science and Systems Engineering',
      'Artificial Intelligence and Machine Learning',
      'Artificial Intelligence',
      'Computer Science Engineering (Data Science)',
      'Computer Science Engineering (Cyber Security)',
      'Civil Engineering',
      'VLSI System Design',
      'Structural Engineering',
      'Embedded Systems',
      'Cyber Security',
      'Data Sciences',
      'Robotics and Automation',
      'Additive Manufacturing',
      'Machine Design',
      'Power Electronics & Electrical Drives',
    ],
  },
  {
    school: 'Management',
    programs: ['Finance', 'Marketing', 'HR', 'Management', 'Strategy'],
  },
  {
    school: 'Pharmacy',
    programs: [
      'B.Pharm',
      'Pharm.D',
      'Pharmaceutics',
      'Pharmacology',
      'Pharmaceutical Analysis',
      'Industrial Pharmacy',
      'Pharma.D. (PB)',
      'Pharmacy',
      'Pharmaceutical Regulatory Affairs',
    ],
  },
  {
    school: 'Medical Science',
    programs: [
      'General Medicine',
      'Paediatrics',
      'General Surgery',
      'OBG',
      'Orthopaedics',
      'Dermatology',
      'Psychiatry',
      'Anaesthesia',
      'Urology',
      'Neuro Physics',
      'Medical Gastroenterology',
      'Respiratory Medicine',
      'Radio Diagnosis',
      'ENT',
      'Ophthalmology ',
      'Anatomy',
      'Physiology',
      'Biochemistry',
      'Pharmacology',
      'Pathology',
      'Microbiology',
      'Forensic Medicine',
      'Community Medicine',
    ],
  },
  {
    school: 'Humanity and Sciences',
    programs: [
      'Maths',
      'Physics',
      'Chemistry',
      'English',
      'Psychology',
      'Statistics',
    ],
  },
  {
    school: 'Agricultural Sciences',
    programs: [
      'Bachelor of Science (Hons) Agriculture',
      'Agronomy',
      'Generic & Plant Breeding',
      'Plant Pathology',
      'Soil Science & Agricultural Chemistry',
      'Crop Physiology',
      'Horticulture',
      'Agriculture Extension',
      'Agricultural Microbiology',
    ],
  },
]

const prefixItems = ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Dr.']

export default function Careers() {
  const [school, setSchool] = useState(null)
  const [prefix, setPrefix] = useState('Mr.')
  const [form] = useForm()
  const ref: any = React.createRef()
  let width
  useEffect(() => {
    width = ref.current.clientWidth
    console.log(width)
  }, [])
  console.log(width)

  function handleFinish(values) {
    const mobile = values.mobile
    if (!(mobile.length == 10)) {
      Modal.error({ content: 'Mobile number must be 10 digits' })
      return
    }
    const body = {
      ...values,
      prefix: prefix,
    }

    axios
      .post('/square/careers', body)
      .then((res) => {
        form.resetFields()
        showDialog({
          title: '',
          footer: false,
          okTitle: 'Detain',
          cancelTitle: 'Cancel',
          content: ({ setLoading, hideDialog }) => (
            <Row gutter={[24, 12]} style={{ textAlign: 'center', padding: 24 }}>
              <Col xs={24}>
                <Image src={success} />
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                  Thank you for choosing AU Careers.
                </div>
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 12, color: 'gray' }}>
                  {'We will get back to you with further steps ' +
                    'through the Mail ID. For any queries, Contact Administration.'}
                </div>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    hideDialog()
                    form.resetFields()
                    window.location.replace('https://anurag.edu.in/')
                  }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          ),
        })
      })
      .catch((e) => serverErrorDialog({ error: e }))
  }
  // return (
  //   <div ref={ref}>
  //     <div className="mobileHidden">
  //       <div
  //         style={{padding: 16, width: "60%", margin: "auto", display: "flex", justifyContent: "space-between"}}>
  //         <Image preview={false} onClick={() => window.location.replace('https://anurag.edu.in/')}
  //                width={200} src={"https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png"}/>
  //         <div style={{display: "flex", alignItems: "center"}}>
  //           <Space>
  //             <a href={'https://agi.anurag.edu.in/'} style={{fontSize: 14, fontWeight: "bold", color: '#C4333E'}}>
  //               GO TO AGI WEBSITE
  //             </a>
  //             <Button href={"https://anurag.edu.in/request-info/"} type="primary" size="large">
  //               REQUEST INFO
  //             </Button>
  //           </Space>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="mobileVisible">
  //       <div
  //         style={{padding: 16, width: "100%", margin: "auto", display: "flex", justifyContent: "space-between"}}>
  //         <Image onClick={() => window.location.replace('https://anurag.edu.in/')} width={140}
  //                src={"https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png"}/>
  //         <div style={{alignItems: "center"}}>
  //           <Button href={"https://anurag.edu.in/request-info/"} type="primary" size="large">
  //             REQUEST INFO
  //           </Button>
  //           <div>
  //             <a href={'https://agi.anurag.edu.in/'} style={{fontSize: 14, fontWeight: "bold", color: '#C4333E'}}>
  //               GO TO AGI WEBSITE
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="mobileHidden" style={{position: "relative"}}>
  //       <Image preview={false} width={"100%"} src={banner}/>
  //     </div>
  //     <div className="mobileVisible" style={{position: "relative"}}>
  //       <Image preview={false} height={160} width={"100%"} src={bannerMobile}/>
  //       <div style={{position: "absolute", top: "40%", left: "0%", color: "white"}}>
  //         <div style={{fontSize: 24, fontWeight: "bold", textAlign: "center"}}>LEAD THE LEADERS OF TOMORROW</div>
  //       </div>
  //     </div>
  //     <Container>
  //      <div style={{textAlign:"center",paddingTop:100}}> <h2>Applications are closed</h2></div>
  //     </Container>
  //   </div>
  // )
  return (
    <div ref={ref}>
      <div className="mobileHidden">
        <div
          style={{
            padding: 16,
            width: '60%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={200}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a>
              <Button
                href={'https://anurag.edu.in/request-info/'}
                type="primary"
                size="large"
              >
                REQUEST INFO
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <div className="mobileVisible">
        <div
          style={{
            padding: 16,
            width: '100%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={140}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ alignItems: 'center', display: 'flex', gap: '20px' }}>
            <Button
              href={'https://anurag.edu.in/request-info/'}
              type="primary"
              size="large"
            >
              REQUEST INFO
            </Button>
            <div>
              <a
                href={'https://agi.anurag.edu.in/'}
                style={{ fontSize: 14, fontWeight: 'bold', color: '#C4333E' }}
              >
                GO TO AGI WEBSITE
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileHidden" style={{ position: 'relative' }}>
        <Image preview={false} width={'100%'} src={banner} />
      </div>
      <div className="mobileVisible" style={{ position: 'relative' }}>
        <Image preview={false} height={160} width={'100%'} src={bannerMobile} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '0%',
            color: 'white',
          }}
        >
          <div
            style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
          >
            LEAD THE LEADERS OF TOMORROW
          </div>
        </div>
      </div>
      <Container>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={12} xl={16} xxl={16}>
            <h2 className="title">
              {
                'APPLY HERE TO JOIN ANURAG UNIVERSITY, A LEADING EDUCATIONAL INSTITUTION.'
              }
            </h2>
            {/* <h2>{"(Last date for application - 27th of JUNE 2021)"}</h2> */}
          </Col>
          <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
            <div
              className="title"
              style={{
                background:
                  'transparent linear-gradient(99deg, #C02633 0%, #E55561 100%) 0% 0% no-repeat',
                padding: 8,
                color: 'white',
              }}
            >
              All Positions open in Engineering, Management, Pharmacy,
              Agricultural Sciences, Liberal Arts and Sciences
            </div>
          </Col>
        </Row>
        <Form
          onFinish={handleFinish}
          form={form}
          layout="vertical"
          initialValues={{
            name: null,
            position: null,
          }}
        >
          <Row gutter={[24, 12]}>
            <Col xs={24}>
              <Form.Item
                name="position"
                label="Which position you would like to apply for?"
                rules={[{ required: true, message: 'Required' }]}
              >
                {/*<Label>What do you want to apply for?</Label>*/}
                <Radio.Group>
                  <Radio value={'Professor'}>Professor</Radio>
                  <Radio value={'Associate Professor'}>
                    Associate Professor
                  </Radio>
                  <Radio value={'Assistant Professor'}>
                    Assistant Professor
                  </Radio>
                  <Radio value={'Other'}>Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <Form.Item
                name="school"
                label="Select a School"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select
                  placeholder="Select a school"
                  size={'large'}
                  onChange={(item) => {
                    setSchool(item)
                    form.setFieldsValue({ specialization: null })
                  }}
                >
                  {data.map((item, index) => (
                    <Option value={item.school} key={index}>
                      {item.school}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name="specialization"
                label="Choose a Specialisation"
              >
                <Select placeholder="Select Specialisation" size={'large'}>
                  {school &&
                    data
                      .filter((item, index) => item.school === school)[0]
                      .programs.map((p, index) => (
                        <Option value={p} key={index}>
                          {p}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name="name"
                label="What is your name?"
              >
                <Input
                  name={'name'}
                  addonBefore={
                    <Select
                      defaultValue="Mr."
                      className="select-before"
                      onChange={(e) => setPrefix(e)}
                    >
                      {prefixItems.map((item, index) => (
                        <Option value={item} key={index}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  }
                  placeholder="Enter your name"
                  size={'large'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name="mobile"
                label="Enter your mobile number"
              >
                <Input placeholder="Enter your mobile number" size={'large'} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name="email"
                label="Enter your E-Mail ID"
              >
                <Input
                  type="email"
                  placeholder="Enter your E-Mail ID"
                  size={'large'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <Form.Item
                rules={[{ required: true, message: 'Required' }]}
                name="pdf"
                label="Upload your CV"
              >
                <CustomUpload
                  accept=".pdf,.doc,.docx"
                  label={'Click to upload you CV(.pdf, doc, docx.)'}
                  onChange={(e) => {
                    const fileExtension = e.name.split('.').pop()
                    const name = form.getFieldValue('name')
                    const profession = form.getFieldValue('position')
                    let formData = new FormData()
                    formData.append('file', e)
                    formData.append('type', 'career')
                    formData.append('fileName', `${name}_${profession}`)
                    formData.append('fileExtension', fileExtension)
                    formData.append('contentType', e.type)
                    axios
                      .post('/square/careers/upload', formData)
                      .then((res) => {
                        form.setFieldsValue({ pdf: res.data.key })
                      })
                      .catch((e) => console.log(e))
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}
