import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import createGeneralApplicationSlice from './slices/createGeneralApplicationSlice'
import createPHDReducer from './slices/createPHDFormSlice'
import selectProgram from './slices/selectProgram'

export const rootReducer = combineReducers({
  createPHDForm: createPHDReducer,
  createGeneralApplication: createGeneralApplicationSlice,
  selectProgram: selectProgram,
})
