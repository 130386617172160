/* eslint-disable no-console */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import banner from 'assets/images/banner.jpg'
import bannerMobile from 'assets/images/banner_mobile.jpeg'
import success from 'assets/images/succes.svg'
import { Container } from 'pages/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  selectCourse,
  selectProgram,
  updateUniversities,
  updateValue,
} from 'redux/slices/createPHDFormSlice'
import CustomUpload from '../../components/CustomUpload'
import CustomUploadNew from '../../components/CustomUploadNew'
import serverErrorDialog from '../../components/serverErrorDialog'
import showDialog from '../../components/showDialog'
import { yearOptions } from '../../constants/UIConstants'
import axios from '../../utils/axios'
import { PreviewData } from './PreviewData'
import { StyledTableContainer, Wrapper } from './Styles'

const { Option } = Select

export default function MTechForm() {
  const [school, setSchool] = useState(null)
  const [program, setProgram] = useState(null)
  const [examProgress, setExamProgress] = useState(false)
  const [search, setSearch] = useSearchParams()
  const utmSource = search.get('utm_source')
  const utmMedium = search.get('utm_medium')
  const utmCampaign = search.get('utm_campaign')

  const [form] = useForm()
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.createPHDForm)
  const ref: any = React.createRef()

  let url = new URLSearchParams(window.location.search)

  useEffect(() => {
    dispatch(selectCourse(url.get('course')))
  }, [])

  useEffect(() => {
    dispatch(selectProgram(url.get('program')))
  }, [])

  function handleFinish() {
    if (!(state.selected.mobile.length == 10)) {
      Modal.error({ content: 'Mobile number must be 10 digits' })
      return
    }

    if (state.selected.caste != 'OC' && !state.selected.casteCertificate) {
      Modal.error({ content: 'Select Upload Cast category certificate' })
      return
    }

    const filledRows = state.selected.institutionsAttended.filter(
      (institution) =>
        institution.university ||
        institution.degree ||
        institution.year ||
        institution.cgpa ||
        institution.class ||
        institution.subjects ||
        institution.certificate,
    ).length

    if (filledRows < 3) {
      Modal.error({
        content:
          'Please fill the institution Details. SSC , Inter/Diploma , U.G , P.G',
      })
      return
    }

    if (state.selected.acceptedTerms == false) {
      Modal.error({ content: 'Please accept Declaration' })
      return
    }

    const institutionsAttended = state.selected.institutionsAttended

    for (let i = 0; i < institutionsAttended.length; i++) {
      const institution = institutionsAttended[i]

      if (institution.university && !institution.certificate) {
        Modal.error({
          content: `Please upload the certificate for ${institution.university}`,
        })
        return
      }
    }

    const handleConfirm = () => {
      Modal.confirm({
        title: 'Preview Your Data',
        width: '80%',
        content: <PreviewData data={state?.selected} />,
        onOk() {
          processData()
        },
        onCancel() {
          console.log('User canceled the submission')
        },
      })
    }
    handleConfirm()

    const processData = async () => {
      const data = {
        ...state.selected,
        admissionCategory: 'Group-B',
        admissionSubcategory: 'Full-time',
        school: school,
        specialization: program,
        utmSource: utmSource,
        utmMedium: utmMedium,
        utmCampaign: utmCampaign,
        caste:
          state.selected.caste === 'OTHER'
            ? state.selected.otherBackwardClass
            : state.selected.caste,
        dob: state?.selected.dob?.format('YYYY-MM-DD'),
        examValidUpto: state?.selected.examValidUpto?.format('YYYY-MM-DD'),
        institutionsAttended: state.selected.institutionsAttended.filter(
          (item) => item.university !== '',
        ),
      }

      setExamProgress(true)

      try {
        await axios.post('/square/mtech', data)
        showDialog({
          title: '',
          footer: false,
          okTitle: 'OK',
          cancelTitle: 'Cancel',
          content: ({ setLoading, hideDialog }) => (
            <Row gutter={[24, 12]} style={{ textAlign: 'center', padding: 24 }}>
              <Col xs={24}>
                <Image preview={false} src={success} />
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                  Thank you for choosing AU.
                </div>
              </Col>
              <Col xs={24}>
                <div style={{ fontSize: 12, color: 'gray' }}>
                  {'We will get back to you with further steps ' +
                    'through the Mail ID. For any queries, Contact Administration.'}
                </div>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    hideDialog()
                    form.resetFields()
                    window.location.replace('https://anurag.edu.in/')
                  }}
                >
                  Done
                </Button>
              </Col>
            </Row>
          ),
        })
      } catch (e) {
        setExamProgress(false)
        serverErrorDialog({ error: e })
      }
    }
  }

  return (
    <div ref={ref}>
      <div className="mobileHidden">
        <div
          style={{
            padding: 16,
            width: '60%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={200}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <Button
                href={'https://anurag.edu.in/'}
                type="primary"
                size="large"
              >
                REQUEST INFO
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <div className="mobileVisible">
        <div
          style={{
            padding: 16,
            width: '100%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Image
            preview={false}
            onClick={() => window.location.replace('https://anurag.edu.in/')}
            width={140}
            src={'https://anurag.edu.in/wp-content/uploads/2020/02/logo-1.png'}
          />
          <div style={{ alignItems: 'center' }}>
            <Button
              href={'https://anurag.edu.in/request-info/'}
              type="primary"
              size="large"
            >
              REQUEST INFO
            </Button>
          </div>
        </div>
      </div>
      <div className="mobileHidden" style={{ position: 'relative' }}>
        <Image preview={false} width={'100%'} src={banner} />
      </div>
      <div className="mobileVisible" style={{ position: 'relative' }}>
        <Image preview={false} height={160} width={'100%'} src={bannerMobile} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '10%',
            color: 'white',
          }}
        >
          <div
            style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
          >
            LEAD THE LEADERS OF TOMORROW
          </div>
        </div>
      </div>
      <Container>
        <Row gutter={[24, 14]} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <h3 style={{ textAlign: 'center' }} className="title">
              Application Form for Admission into M Tech Program
            </h3>
          </Col>
        </Row>
        {state.selected.acceptRegulations && (
          <Form
            onFinish={() => {}}
            form={form}
            layout="vertical"
            initialValues={{
              name: null,
              position: null,
            }}
          >
            <Row gutter={[24, 12]}>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    value={state.selected.name}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'name',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Gender"
                  name={'gender'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.gender}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'gender',
                        }),
                      )
                    }}
                  >
                    {['male', 'female'].map((item, index) => (
                      <Option value={item} key={index}>
                        {item.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Father's/Spouse's Name"
                  name={'fatherName'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.fatherName}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'fatherName',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Mother's Name"
                  name={'motherName'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.motherName}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'motherName',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Date of birth"
                  name={'dob'}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    value={state.selected.dob}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'dob',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Marital Status"
                  name={'MaritalStatus'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.maritalStatus}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'maritalStatus',
                        }),
                      )
                    }}
                  >
                    {['married', 'unmarried'].map((item, index) => (
                      <Option value={item} key={index}>
                        {item.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Nationality"
                  name={'nationality'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.nationality}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'nationality',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item
                  label="Mother Tongue"
                  name={'motherTongue'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.motherTongue}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'motherTongue',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Caste(if other than open category (OC), a category certificate must be enclosed)"
                  name={'caste'}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={state.selected.caste}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e,
                          key: 'caste',
                        }),
                      )
                    }}
                  >
                    {['OC', 'SC', 'ST', 'OBC', 'PWD', 'OTHER'].map(
                      (item, index) => (
                        <Option value={item} key={index}>
                          {item.toUpperCase()}
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              {state.selected.caste == 'OTHER' && (
                <Col xs={24}>
                  <Form.Item label="Specify if you belong to any other Backward Class">
                    <Input
                      value={state.selected.otherCaste}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toUpperCase()
                      }}
                      onChange={(e) => {
                        dispatch(
                          updateValue({
                            value: e.target.value.toUpperCase(),
                            key: 'otherCaste',
                          }),
                        )
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {state.selected.caste != 'OC' && (
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Upload your Caste certificate"
                    name={'caste-certificate'}
                    rules={[{ required: true }]}
                  >
                    <CustomUpload
                      accept=".pdf,image/*"
                      label={
                        'Click to upload your Caste certificate (.pdf, jpeg)'
                      }
                      onChange={(e) => {
                        const fileExtension = e.name.split('.').pop()
                        let formData = new FormData()
                        formData.append('file', e)
                        formData.append('type', 'caste_certificate')
                        formData.append(
                          'fileName',
                          `${state.selected.name}_caste_certificate`,
                        )
                        formData.append('fileExtension', fileExtension)
                        setExamProgress(true)
                        axios
                          .post('/square/mtech/upload', formData)
                          .then((res) => {
                            setExamProgress(false)
                            dispatch(
                              updateValue({
                                value: res.data.key,
                                key: 'casteCertificate',
                              }),
                            )
                          })
                          .catch((e) => console.log(e))
                      }}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24}>
                <Form.Item
                  label="Religion"
                  name={'religion'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.religion}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'religion',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  label="Enter your mobile number"
                  name={'mobile'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.mobile}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'mobile',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  label="Enter your E-Mail ID"
                  name={'email'}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={state.selected.email}
                    type="email"
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toUpperCase()
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateValue({
                          value: e.target.value.toUpperCase(),
                          key: 'email',
                        }),
                      )
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  name={'school'}
                  rules={[{ required: school ? false : true }]}
                  label="Program"
                >
                  <Select
                    defaultValue={school}
                    value={school}
                    onChange={(e) => {
                      setSchool(e)
                      dispatch(selectCourse(e))
                    }}
                  >
                    {state.mtech?.map((item, index) => (
                      <Option value={item.course} key={index}>
                        {item.course}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                <Form.Item
                  name={'specialization'}
                  rules={[{ required: program ? false : true }]}
                  label="Courses"
                >
                  <Select
                    defaultValue={program}
                    value={program}
                    onChange={(e) => {
                      setProgram(e)
                      dispatch(selectProgram(e))
                    }}
                  >
                    {state.mtech
                      .filter((item) => item.course == state.selectedCourse)[0]
                      ?.programs.map((p, index) => (
                        <Option value={p} key={index}>
                          {p}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                <Form.Item label="Communication Address">
                  <Row>
                    <Col xs={24}>
                      <Form.Item
                        label="Address"
                        name={'address'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.address}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'address',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="Dist"
                        name={'district'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.district}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'district',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="State"
                        name={'state'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.state}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.toUpperCase()
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'state',
                                value: e.target.value.toUpperCase(),
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="PinCode"
                        name={'pincode'}
                        rules={[{ required: true }]}
                      >
                        <Input
                          value={state.selected.pincode}
                          onChange={(e) => {
                            dispatch(
                              updateValue({
                                key: 'pincode',
                                value: e.target.value,
                              }),
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Wrapper>
                <Col xs={24}>
                  <Form.Item
                    label="Details of Universities/Institutions attended (from 10th Standard onwards) (certificates and mark sheets/grade cards/original degree mandatory)"
                    name={'details'}
                    rules={[{ required: true }]}
                  >
                    <StyledTableContainer>
                      <table>
                        <thead>
                          <th>University/College/ Board</th>
                          <th>Degree obtained (with discipline)</th>
                          <th>Year</th>
                          <th>% Marks/ CGPA</th>
                          <th>Class Obtained (if applicable)</th>
                          <th>Subjects taken (GROUP)</th>
                          <th>Certificate</th>
                        </thead>
                        <tbody>
                          {state.selected.institutionsAttended.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .university
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'university',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .degree
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'degree',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Select
                                    style={{ width: 80 }}
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .year
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e,
                                          key: 'year',
                                        }),
                                      )
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {yearOptions.map((item, index) => (
                                      <Option value={item} key={index}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .cgpa
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value,
                                          key: 'cgpa',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .class
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'class',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={
                                      state.selected.institutionsAttended[index]
                                        .subjects
                                    }
                                    onInput={(e: any) => {
                                      e.target.value =
                                        e.target.value.toUpperCase()
                                    }}
                                    onChange={(e) => {
                                      dispatch(
                                        updateUniversities({
                                          index: index,
                                          value: e.target.value.toUpperCase(),
                                          key: 'subjects',
                                        }),
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <CustomUploadNew
                                    accept=".pdf,image/*"
                                    label={'Upload'}
                                    validation={
                                      state.selected.institutionsAttended[index]
                                        .university
                                    }
                                    onChange={(e) => {
                                      const fileExtension = e.name
                                        .split('.')
                                        .pop()
                                      let formData = new FormData()
                                      formData.append('file', e)
                                      formData.append(
                                        'type',
                                        'university_certificate',
                                      )
                                      formData.append(
                                        'fileName',
                                        `${state.selected.name}_${state.selected.institutionsAttended[index].university}_education_certificate`,
                                      )
                                      formData.append(
                                        'fileExtension',
                                        fileExtension,
                                      )
                                      setExamProgress(true)

                                      axios
                                        .post('/square/mtech/upload', formData)
                                        .then((res) => {
                                          setExamProgress(false)
                                          dispatch(
                                            updateUniversities({
                                              value: res.data.key,
                                              key: 'certificate',
                                              index: index,
                                            }),
                                          )
                                        })
                                        .catch((e) => console.log(e))
                                    }}
                                  />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </StyledTableContainer>
                  </Form.Item>
                </Col>
              </Wrapper>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <Form.Item
                  name={'photo'}
                  rules={[{ required: true }]}
                  label="Upload Photo"
                >
                  <CustomUpload
                    accept="image/jpeg"
                    label={'Please upload passport size photo(.jpeg,.jpg)'}
                    onChange={(e) => {
                      const fileExtension = e.name.split('.').pop()
                      let formData = new FormData()
                      formData.append('file', e)
                      formData.append('type', 'photo')
                      formData.append(
                        'fileName',
                        `${state.selected.name}_photo`,
                      )
                      formData.append('fileExtension', fileExtension)
                      setExamProgress(true)
                      axios
                        .post('/square/mtech/upload', formData)
                        .then((res) => {
                          setExamProgress(false)
                          dispatch(
                            updateValue({
                              value: res.data.key,
                              key: 'photo',
                            }),
                          )
                        })
                        .catch((e) => console.log(e))
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  name={'acceptedTerms'}
                  rules={[{ required: true, message: '' }]}
                  label={'Declaration'}
                >
                  <Checkbox
                    checked={state.selected.acceptedTerms}
                    onChange={(e) =>
                      dispatch(
                        updateValue({
                          key: 'acceptedTerms',
                          value: e.target.checked,
                        }),
                      )
                    }
                  >
                    I hereby declare that the entries made in this application
                    form are correct to the best of my knowledge and belief. If
                    selected for admission, I promise to abide by the rules and
                    regulations of the Anurag University (AU) as amended from
                    time to time. I note that the decision of the Anurag
                    University is final in regard to selection for admission and
                    assignment to a particular department and field of study.
                    The Anurag University shall have the right to take any
                    action it deems fit, including expulsion, against me at any
                    time after my admission, if it is found that information
                    furnished by me are false or incorrect. I shall abide by the
                    decision of the Institute, which shall be final.
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} style={{ textAlign: 'center' }}>
                <Form.Item>
                  <Button
                    disabled={examProgress}
                    onClick={handleFinish}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} style={{ textAlign: 'center' }}>
                <Form.Item>
                  <div>
                    (If you have any issues in from submission please contact us
                    at <a type="link">aumtechadmissions@anurag.edu.in</a>)
                  </div>
                  <div>
                    Contact numbers - <a type="link">8712158602</a>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </div>
  )
}
