import React from 'react';
import { Col, Modal, Row, Typography } from 'antd'
import _ from 'lodash';
import { AxiosError } from 'axios';
import { errorMonitor } from 'stream';

interface DialogProps {
  error: AxiosError,
  onOk?: (...args: any[]) => any
}

export default function serverErrorDialog({ error, onOk }: DialogProps) {
  if (!error) {
    return
  }

  if (!error.response) {
    Modal.error({
      content: error.message ? error.message : error.toString(),
      onOk: onOk,
    })
  }

  else if (error.response.status == 400) {
    let errorArray = _.get(error.response, 'data.message', [])
    Modal.error({
      content: <Row>
        {
          errorArray.map((item, index) => (
            <Col key={index} xs={24}>
              <Typography.Text>{item}</Typography.Text>
            </Col>
          ))
        }
      </Row>,
      onOk: onOk,
    },
    )
  } else if (error.response.status == 422) {
    let errorMessage = _.get(error.response, 'data.message', 'server error')
    Modal.error({
      content: errorMessage,
      onOk: onOk,
    })
  } else if (error.response.status == 500) {
    let errorMessage = _.get(error.response, 'data.message', 'server error')
    Modal.error({
      content: errorMessage,
      onOk: onOk,
    })
  }
}
