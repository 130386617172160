import { styled } from '@mui/material/styles'

export const StyledTableContainer = styled('div')(({ theme }) => ({
  background: 'white',
  borderRadius: theme.shape.borderRadius,
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
  },
  '& th, & td': {
    border: '1px solid #bebebe',
    padding: theme.spacing(1),
  },
  '& thead tr': {
    backgroundColor: '#efefef',
  },
}))

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '500px',
    overflowX: 'scroll',
  },
}))
