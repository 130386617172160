import React, {useRef, useState} from "react";
import {InboxOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import styled from "styled-components";

const StyledUploadBox = styled.div`
  background: white;
  border: 1px dashed #d9d9d9;
  padding: 1rem;
  cursor: pointer;
  width: 600px;
  text-align: center;
  :hover {
    border-color: #40a9ff;
  }
`


export default function CustomUpload(props: any) {
  const fileRef = useRef<HTMLInputElement>();
  const [state, setState] = useState({
    file: null
  })
  
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept={props.accept}
        onChange={e => {
          if (!e.target.files[0]) {
            return;
          }
          setState({ file: e.target.files[0] })
          props.onChange(e.target.files[0]);
        }}
      />
      
      <StyledUploadBox
        onClick={() => fileRef.current.click()}
      >
        <div>
          <InboxOutlined style={{ fontSize: '48px' }} />
        </div>
        
        <div>
          <Typography.Text>{props.label}</Typography.Text>
        </div>
        
        <div style={{ paddingLeft: 24 }}>
          {state.file ? state.file.name : null}
        </div>
      
      </StyledUploadBox>
    </div>
  )
}