import { Provider } from 'react-redux'
import { Outlet } from 'react-router-dom'
import store from 'redux/store'
import { baseRoutes } from './baseRoutes'

export const mainRoutes = [
  {
    children: [
      {
        path: '/',
        element: (
          <Provider store={store}>
            <Outlet />
          </Provider>
        ),
        children: baseRoutes,
      },
    ],
  },

  {
    path: '*',
    element: (
      <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Page Not Found</h1>
    ),
  },
]
