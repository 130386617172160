import React, { useRef, useState } from 'react'
import { FileOutlined, InboxOutlined } from '@ant-design/icons'
import { Tooltip, Typography, Modal } from 'antd'
import styled from 'styled-components'

const StyledUploadBox = styled.div`
  background: white;
  border: 1px dashed #d9d9d9;
  padding: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  :hover {
    border-color: #40a9ff;
  }
`

export default function CustomUploadNew(props: any) {
  const fileRef = useRef<HTMLInputElement>()
  const [state, setState] = useState({
    file: null,
  })
  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    const element = event.target as HTMLInputElement
    element.value = ''
    if (props.validation == '') {
      Modal.warning({ content: 'Please Enter university Name' })
      return event.preventDefault()
    }
  }
  function showTitle(title) {
    if (title?.length < 10) {
      return title
    } else {
      return `${title?.substring(0, 4)}...`
    }
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept={props.accept}
        onClick={onInputClick}
        onChange={(e) => {
          if (!e.target.files[0]) {
            return
          }
          setState({ file: e.target.files[0] })
          props.onChange(e.target.files[0])
        }}
      />

      <StyledUploadBox onClick={() => fileRef.current.click()}>
        <div>
          <Typography.Text>{props.label}</Typography.Text>
        </div>
        <Tooltip placement="right" title={state.file ? state.file.name : null}>
          <div style={{ textAlign: 'center' }}>
            {state.file ? <FileOutlined style={{ color: '#C02633' }} /> : null}
          </div>
        </Tooltip>
      </StyledUploadBox>
    </div>
  )
}
