import http from 'utils/axios'

declare global {
  interface Window {
    Razorpay: any
  }
}
var razorPayKey

class PaymentService {
  async createOrder(data) {
    return http.post(`/square/phd/create-order`, data)
  }

  async makePhdFeePayment(order, data, verifyPayment: Function, cb: Function) {
    var razorpay = await new window.Razorpay({
      key: order?.razorpayKey,
      image: 'https://ums.aupulse.com/logo.png',
      currency: order?.order?.currency,
      order_id: order?.order?.id,
      email: data?.email,
      contact: data?.mobile,
      theme: {
        color: '#C02632',
      },
      prefill: {
        name: data?.name,
        email: data?.email,
        contact: data?.mobile,
      },
      method: {
        netbanking: true,
        card: true,
        wallet: true,
        upi: true,
      },
      handler: (response) => {
        verifyPayment(response, data)
          .then((res) => {
            document.body.style.removeProperty('overflow')
            cb(null, res)
          })
          .catch((err) => cb(err))
      },
    })

    await razorpay.open()
  }
}

export default new PaymentService()
