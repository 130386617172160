import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'

interface FormDialogProps {
  content: any
  onOk?: Function
  footer?: any
  onCancel?: Function
  title?: string
  okTitle?: string
  cancelTitle?: string
  modalProps?: any

  [x: string]: any
}

export default function showDialog({
  title,
  content,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  footer,
  ...props
}: FormDialogProps) {
  function MyDialog() {
    const [state, setState] = useState({ visible: true, loading: false })

    const handleOk = (e: any) => {
      setState({
        ...state,
        visible: false,
      })
    }

    const handleCancel = (e: any) => {
      setState({
        ...state,
        visible: false,
      })
    }

    const hideDialog = (e: any) => {
      setState({
        ...state,
        visible: false,
      })
    }

    const setLoading = (loading: boolean) => {
      setState({
        ...state,
        loading: loading,
      })
    }

    return (
      <Modal
        title={title}
        visible={state.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        {...props.modalProps}
        footer={
          footer
            ? [
                <Button key="back" size="large" onClick={handleCancel}>
                  {cancelTitle || 'Return'}
                </Button>,
                <Button
                  key="submit"
                  size="large"
                  type="primary"
                  loading={state.loading}
                  onClick={() => {
                    onOk({ setLoading, hideDialog })
                  }}
                >
                  {okTitle || 'Submit'}
                </Button>,
              ]
            : null
        }
        maskClosable={false}
      >
        {content({
          setLoading,
          hideDialog,
        })}
      </Modal>
    )
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )
  root.render(
    <>
      <MyDialog />
    </>,
  )
}
