import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  levelOfStudy: [
    { name: 'Undergraduate (UG)', value: 'UG' },
    { name: 'Postgraduate (PG)', value: 'PG' },
  ],

  data: [
    {
      name: 'UG',
      courses: [
        {
          course: 'School of Engineering',
          programs: [
            'Artificial Intelligence',
            'Artificial Intelligence and Machine Learning',
            'Computer Science and Engineering',
            'Computer science and Engineering (Data Science)',
            'Computer science and Engineering (Cyber Security)',
            'Computer Science and Systems Engineering',
            'Information Technology',
            'Chemical Engineering',
            'Civil Engineering',
            'Electronics and Communication Engineering',
            'Electrical and Electronics Engineering',
            'Mechanical Engineering',
            'Construction Technology and Management',
            'Bachelor of Science- Artificial Intelligence',
            'Bachelor of Science- Data Science',
          ],
        },
        {
          course: 'School of Pharmacy',
          programs: ['Bachelor of Pharmacy'],
        },
        {
          course: 'School of Management',
          programs: ['Bachelor of Business Administration'],
        },
        {
          course: 'School of Agricultural Sciences',
          programs: ['Bachelor of Science (Hons) Agriculture'],
        },
        {
          course: 'School of Liberal Arts',
          programs: [
            'Bachelor of Commerce (Hons)',
            'Bachelor of Journalism',
            'Bachelor of Arts - Economics',
          ],
        },
      ],
    },
    {
      name: 'PG',
      courses: [
        {
          course: 'School of Engineering',
          programs: [
            'VLSI System Design',
            'Structural Engineering',
            'Embedded Systems',
            'Cyber Security',
            'Artificial Intelligence',
            'Data Sciences',
            'Construction Technology and Management',
            'Additive Manufacturing',
            'Robotics and Automation',
            'Machine Design',
            'Power Electronics and Electrical Drives',
            // "Electrical Power Systems",
            'Computer Science and Engineering',
          ],
        },
        {
          course: 'School of Pharmacy',
          programs: [
            'Pharmaceutics',
            'Pharmacology',
            'Industrial Pharmacy',
            'Pharmaceutical Regulatory Affairs',
            'Pharma Analysis',
            'Doctor of Pharmacy',
          ],
        },
        {
          course: 'School of Management',
          programs: ['Master of Business Administration'],
        },
      ],
    },
  ],
  loading: false,
  selectedCourse: null,
  selectedProgram: null,
  selectedLevel: null,
  selected: {
    fullName: null,
    mobile: '',
    email: null,
    city: null,
  },
}

const generalApplication = createSlice({
  name: 'create-general',
  initialState: initialState,
  reducers: {
    updateValue(state, action) {
      let key = action.payload.key
      let value = action.payload.value
      state.selected[key] = value
    },
    selectCourse(state, action) {
      state.selectedCourse = action.payload
    },
    selectLevel(state, action) {
      state.selectedLevel = action.payload
    },
    selectProgram(state, action) {
      state.selectedProgram = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
})

export const {
  updateValue,
  selectCourse,
  selectProgram,
  selectLevel,
  setLoading,
} = generalApplication.actions

export default generalApplication.reducer
