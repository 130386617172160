import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: auto;
  //padding-top: 24px;
  padding: 24px;
  .ant-select-arrow {
    color: #c02633;
  }
  .title {
    text-align: center;
  }
  @media screen and (min-width: 480px) {
    width: 60%;
    margin: auto;
    .title {
      text-align: left;
    }
  }
`
