import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  levelOfStudy: [
    { id: 1, name: 'Undergraduate (UG)', value: 'UG' },
    { id: 2, name: 'Postgraduate (PG)', value: 'PG' },
    { id: 3, name: 'Doctorate Programs (Ph.D)', value: 'Ph.D' },
  ],

  phd: [
    {
      course: 'School of Engineering',
      programs: [
        'Chemical Engineering',
        'Civil Engineering',
        'Computer Science and Engineering',
        'Electrical and Electronics Engineering',
        'Electronics and Communication Engineering',
        'Information Technology',
        'Mechanical Engineering',
        'CSE (specialization in Artificial Intelligence)',
      ],
    },
    {
      course: 'School of Pharmacy',
      programs: ['Pharmacy'],
    },
    {
      course: 'School of Management',
      programs: ['Master of Business Administration'],
    },
    {
      course: 'School of Science and Humanities',
      programs: ['Maths', 'Physics', 'Chemistry', 'English'],
    },
  ],
  mtech: [
    {
      course: 'M.Tech',
      programs: [
        'Artificial Intelligence',
        'Computer Science and Engineering',
        'Construction Technology and Management',
        'Cyber Security',
        'Data Science',
        'Embedded Systems',
        'VLSI System Design',
        'Electrical Power Systems',
        'Power Electronics and Electrical Drives',
        'Machine Design',
        'Structural Engineering',
      ],
    },
  ],
  loading: true,
  selectedCourse: null,
  selectedProgram: null,
  selectedLevel: 3,
  selected: {
    modeOfAdmission: false,
    name: null,
    gender: null,
    mobile: '',
    email: null,
    acceptRegulations: true,
    fatherName: null,
    motherName: null,
    dob: null,
    maritalStatus: null,
    nationality: null,
    caste: 'OC',
    religion: null,
    motherTongue: '',
    school: null,
    specialization: null,
    isEmployer: 'no',
    showExamPassed: 'no',
    examPassed: null,
    examSubject: null,
    examScore: null,
    examValidUpto: null,
    admissionCategory: null,
    admissionSubcategory: null,
    examCertificate: null,
    photo: null,
    address: null,
    district: null,
    state: null,
    pincode: null,
    permAddress: null,
    permDistrict: null,
    permState: null,
    permPincode: null,
    transactionDate: null,
    transactionNo: null,
    transactionAmount: null,
    institutionsAttended: [
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
      {
        university: '',
        degree: '',
        year: '',
        cgpa: '',
        class: '',
        subjects: '',
        certificate: null,
      },
    ],
    experience: [
      { organisationName: '', position: '', from: '', to: '' },
      { organisationName: '', position: '', from: '', to: '' },
      { organisationName: '', position: '', from: '', to: '' },
      { organisationName: '', position: '', from: '', to: '' },
      { organisationName: '', position: '', from: '', to: '' },
    ],
    casteCertificate: null,
    fellowshipCertificate: null,
    acceptedTerms: false,
    employerName: null,
    employerEmail: null,
    employerWebsite: null,
    employerAddress: null,
    employerPhone1: null,
    employerPhone2: null,
    isEmployerPermissionObtained: null,
  },
}

const slice = createSlice({
  name: 'create-phd',
  initialState: initialState,
  reducers: {
    showExamPassed(state, action) {
      if (action.payload.value == 'no') {
        state.selected.examPassed = null
        state.selected.examSubject = null
        state.selected.examValidUpto = null
        state.selected.showExamPassed = 'no'
      } else if (action.payload.value == 'yes') {
        state.selected.examPassed = null
        state.selected.examSubject = null
        state.selected.examValidUpto = null
        state.selected.showExamPassed = 'yes'
      }
    },
    updateValue(state, action) {
      let key = action.payload.key
      let value = action.payload.value
      state.selected[key] = value
    },
    updateUniversities(state, action) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.selected.institutionsAttended[index][key] = value
    },
    updateExperience(state, action) {
      let index = action.payload.index
      let key = action.payload.key
      let value = action.payload.value
      state.selected.experience[index][key] = value
    },
    updateAcceptRegulations(state, action) {
      state.selected.acceptRegulations = action.payload.value
    },
    selectCourse(state, action) {
      state.selectedCourse = action.payload
    },
    selectProgram(state, action) {
      state.selectedProgram = action.payload
    },
  },
})

export const {
  updateUniversities,
  updateValue,
  updateExperience,
  updateAcceptRegulations,
  showExamPassed,
  selectCourse,
  selectProgram,
  // updatePermanentAddValue,
  // updateCommunicationAddValue
} = slice.actions

export default slice.reducer
